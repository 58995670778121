import React, {useEffect} from 'react';
import './Product.scss'
import '../home/Home.scss'

import ProductDataSpader from "./imageProduct/img_16.png";
import Impactful from "./imageProduct/img_2.png";
import Service from "./imageProduct/img_3.png";
import Predictive from "./imageProduct/img_4.png";
import Disruptive from "./imageProduct/img_5.png";
import Culture from "./imageProduct/img_6.png";
import Strategy from "./imageProduct/img.png";
import Recruitment from './imageProduct/img_8.png'
import Leadership from './imageProduct/img_12.png'
import OurStep from './imageProduct/img_1.png'
import Step_1 from './imageProduct/img_15.png'
import Step_2 from './imageProduct/img_13.png'
import Step_3 from './imageProduct/img_14.png'
import Step_4 from './imageProduct/img_11.png'
import AOS from "aos";
import {HashLink as Link} from 'react-router-hash-link';
import {useTranslation} from "react-i18next";
import {Helmet} from "react-helmet";


const Product = () => {

    function ScrollToTopOnMount() {
        useEffect(() => {
            window.scrollTo(0, 0);
        }, []);

        return null;
    }

    useEffect(() => {
        AOS.init({
            duration: 2000,
            disable: window.innerWidth < 1300,
        });
        AOS.refresh();
    }, []);


    const {t} = useTranslation()
    return (
        <div className={'home_wrapper'}>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>Product | Flowle.com</title>

            </Helmet>
            <ScrollToTopOnMount/>
            <section id='Smart' className={'home_product_1'}>
                <div className={'Product_wrapper'}>
                    <div className={'product_Data'}>

                        <div className={'tex_product_wrapper'}>
                            <div className={'product_text'}>
                                <h2 data-aos="fade-right">
                                    {t('With Smart Data to Success.')}
                                </h2>

                                <hr/>
                                <h4  data-aos="fade-left">
                                    {t('Are you ready')}
                                </h4>
                                <p data-aos="fade-right">
                                    {t('Smart Data Analytics is the magic word.')}

                                    <br/>
                                    {t('FLOWLE\'s cutting-edge')}

                                </p>
                            </div>

                            <div className={'product_image_spader'}>
                                <img src={ProductDataSpader} alt={'ProductData_spader'}/>
                            </div>
                        </div>
                    </div>
                    <div className={'product_text_box'}>
                        <h2>
                            {t('What are you waiting')}
                            <br/>
                            {t("The future is FLOWLE.")}
                        </h2>
                    </div>

                    <div className={'product_icon_boxes'}>
                        <div className={'product_boxes'}>
                            <div className={'product_box'}>
                                <img data-aos="flip-left" src={Impactful} alt={'Impactful'}/>
                                <h3>
                                    {t('Impactful Data')}

                                </h3>
                                <p>
                                    {t('Data-backed')}

                                </p>
                            </div>
                            <div className={'product_box'}>
                                <img data-aos="flip-left" src={Service} alt={'Impactful'}/>
                                <h3>
                                    {t('Culture-as')}

                                </h3>
                                <p>
                                    {t('Cloud-based data')}

                                </p>
                            </div>
                            <div className={'product_box'}>
                                <img data-aos="flip-right" src={Predictive} alt={'Impactful'}/>
                                <h3>
                                    {t('Predictive / AI-Ready Tool')}

                                </h3>
                                <p>
                                    {t('Data-based predictions')}

                                </p>
                            </div>
                            <div className={'product_box'}>
                                <img data-aos="flip-right" src={Disruptive} alt={'Impactful'}/>
                                <h3>
                                    {t('Disruptive')}

                                </h3>
                                <p>
                                    {t('A new, innovative')}

                                </p>
                            </div>
                        </div>

                    </div>

                </div>

            </section>


            <section id='Culture' className={'home_product'}>

                <div className={'Culture_wrapper'}>
                    <div className={'Culture_text'}>

                        <h2>

                            FLOWLE | Culture Data
                        </h2>


                        <p>
                            {t('Our Smart Data Analytics makes')}
                            <br/>
                            {t('The FLOWLE assessment tool')}
                        </p>
                    </div>

                    <div className={'Culture_img'}>
                        <img data-aos="flip-left"
                             data-aos-easing="ease-out-cubic"
                             data-aos-duration="2000"
                             src={Culture} alt={'Culture'}/>
                    </div>
                </div>
            </section>

            <section id='Strategy' className={'home_product'}>

                <div className={'Strategy_wrapper'}>
                    <div className={'Strategy_img'}>
                        <img data-aos="flip-left"
                             data-aos-easing="ease-out-cubic"
                             data-aos-duration="2000"
                             src={Strategy} alt={'Strategy'}/>
                    </div>
                    <div className={'Strategy_text'}>
                        <h2 style={{color: '#009FBF'}}>

                            FLOWLE | Strategy

                        </h2>
                        <p>
                            {t('We translate your business')}

                        </p>
                    </div>
                </div>

            </section>

            <section id='Recruitment' className={'home_product'}>
                <div className={'Culture_wrapper'}>
                    <div className={'Culture_text'}>
                        <h2 style={{color: '#1CAFA0'}}>FLOWLE | Recruitment</h2>
                        <p>{t('Organizations are a network')}
                        </p>
                    </div>

                    <div style={{borderTopRightRadius: '100px', borderTopLeftRadius: '0px'}} className={'Culture_img'}>
                        <img data-aos="flip-left"
                             data-aos-easing="ease-out-cubic"
                             data-aos-duration="2000" src={Recruitment} alt={'Recruitment'}/>
                    </div>
                </div>
            </section>

            <section id='Leadership' className={'home_product'}>
                <div className={'Strategy_wrapper'}>
                    <div style={{
                        borderBottomLeftRadius: '0px',
                        borderBottomRightRadius: '100px',
                    }}
                         className={'Strategy_img'}>
                        <img className={'head'} data-aos="flip-left"
                             data-aos-easing="ease-out-cubic"
                             data-aos-duration="2000"
                             src={Leadership} alt={'Leadership'}/>
                    </div>
                    <div className={'Strategy_text'}>
                        <h2 style={{color: '#7D69A8'}}>FLOWLE | Leadership</h2>
                        <p>{t('The strongest cultural influencer')}</p>
                    </div>
                </div>
            </section>


            <section id='Process' className={'home_product'}>
                <div className={'organization_product'}>

                    <div className={'organization_wrapper_product'}>
                        <h2>
                            {t('Interested in trying')}
                            <br/>
                            {t('us!')}

                        </h2>


                        <div>
                            <Link to="demo">

                                <button className={'sing_In'}>
                            <span className={'Button-Inner'}>
                                {t('a demo')}

                               </span>
                                </button>

                            </Link>


                        </div>


                    </div>
                </div>
                <div className={'OurStep_wrapper'}>

                    <div data-aos="fade-up"
                         data-aos-duration="3000"
                         className={'Process_text'}>
                        <h2>
                            {t('Our 4-Step Process to a')}
                            <br/>
                            {t('leistungsstarken Kultur')}


                        </h2>
                        <hr/>


                    </div>
                    <div data-aos="fade-up"
                         data-aos-duration="3000"
                         className={'Process_title'}>
                        <p>
                            {t('We do everything we can')}

                        </p>
                    </div>


                </div>


                 <div className={'StepUs_mobail'} >

    <div className={'OurStep_text'}>

            <img style={{width: '76px', height: '76px',marginBottom: '30px'}} src={Step_1} alt={'Step_1'}/>


        <h6>
            {t('ONBOARD')}

        </h6>
        <p style={{color: '#000000'}}>
            {t('team & involved people')}

        </p>
    </div>

    <div className={'OurStep_text'}>
        <img src={Step_2} alt={'Step_2'}/>
        <h6>
            {t('ASSESS')}
        </h6>
        <p style={{color: '#000000'}}>
            {t('the culture status quo')}
        </p>
    </div>


    <div className={'OurStep_text'}>
        <img src={Step_3} alt={'Step_3'}/>
        <h6  style={{color: '#009FBF'}}>
            {t('ANALYSE')}

        </h6>
        <p style={{color: '#009FBF'}}>
            {t('the survey results')}

        </p>
    </div>


    <div className={'OurStep_text'}>
        <img style={{width: '52px', height: '52px',marginBottom: '30px'}} src={Step_4} alt={'Step_4'}/>
        <h6  style={{color: '#009FBF'}}>
            {t('ACT')}

        </h6>
        <p style={{color: '#009FBF'}}>
            {t('& take required measures')}
        </p>
    </div>

</div>

                <div className={'OurStep_image'}>




                    <img src={OurStep} alt={'OurStep'}/>
                    <div data-aos="fade-down"
                         data-aos-easing="linear"
                         data-aos-duration="2500"
                         className={'OurStep_text_1'}>


                        <h6>
                            {t('ONBOARD')}

                        </h6>
                        <p style={{color: '#000000'}}>
                            {t('team & involved people')}

                        </p>
                    </div>

                    <div data-aos="fade-down"
                         data-aos-easing="linear"
                         data-aos-duration="2500"
                         className={'OurStep_text_2'}>
                        <h6>
                            {t('ASSESS')}
                        </h6>
                        <p style={{color: '#000000'}}>
                            {t('the culture status quo')}
                        </p>
                    </div>


                    <div data-aos="fade-down"
                         data-aos-easing="linear"
                         data-aos-duration="3000"
                         className={'OurStep_text_3'}>
                        <h6>
                            {t('ANALYSE')}

                        </h6>
                        <p>
                            {t('the survey results')}

                        </p>
                    </div>


                    <div data-aos="fade-down"
                         data-aos-easing="linear"
                         data-aos-duration="3500"
                         className={'OurStep_text_4'}>
                        <h6>
                            {t('ACT')}

                        </h6>
                        <p>
                            {t('& take required measures')}

                        </p>
                    </div>

                </div>


            </section>
        </div>
    );
};

export default Product;