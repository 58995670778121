import React, {useEffect, useState} from 'react';
import './Header.scss'
import {Outlet} from "react-router-dom";
import Scrolling from "../Scrolling/Scrolling";
import For from "../footer/footer";
import DecstTopHeader from "./DecstTopHeader";
import MobileHeader from "./MobileHeader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFacebookF, faInstagram, faLinkedinIn} from "@fortawesome/free-brands-svg-icons";

import {HashLink as Link} from 'react-router-hash-link';
import Language from "../language/language";
import Flo from "../footer/img.png";
import Cookie from "../cookie/Cookie";
import {useTranslation} from "react-i18next";



const Header = () => {


    const [colorChange, setColorchange] = useState(false);

    useEffect(() => {
        window.addEventListener('scroll', changeNavbarColor);
        return () => window.removeEventListener('scroll', changeNavbarColor);
    }, []);


    const changeNavbarColor = () => {
        if (window.scrollY >= 80) {
            setColorchange(true);
        } else {
            setColorchange(false);
        }
    };

    const {t} = useTranslation()





    return (

        <div className={'header_wrapper'}>
            <div className={colorChange ? 'header colorChange' : 'header'}>

                <div className={'header_top'}>
                    <div className={'header_icons_left'}>

                        <a style={{position:'relative'}} hrefLang="chart" rel="opener" href="https://instagram.com/growithy?utm_medium=copy_link">
                            <FontAwesomeIcon className={'singnup'} icon={faInstagram} />
                        </a>

                        <a style={{position:'relative'}}  hrefLang="chart" target="_blank" className={'lin'} href="https://www.linkedin.com/company/flowle">
                            <FontAwesomeIcon className={'singnup'} icon={faLinkedinIn}/>
                        </a>

                        <a style={{position:'relative'}} hrefLang="chart" target="_blank" href="https://www.facebook.com/FlowleCultureData">

                            <FontAwesomeIcon className={'singnup'} icon={faFacebookF}/>
                        </a>
                    </div>

                    <div className={'header_icons_right'}>


                            <Link style={{position:'relative'}}   to="contactUs">
                                      <span>
                                {t('Contact')}
                                </span>
                            </Link>

                        <Language/>
                    </div>
                </div>
                <div className={'header_bottom'}>
                    <span className={'ghost-bar'}> </span>
                    <div className={'header_left'}>


                        <div className={'logo'}>

                            <Link to={"/"}>
                                <img src={Flo} alt={'flo'}/>
                            </Link>


                        </div>

                    </div>

                    <DecstTopHeader/>



<div className={'login_button'}>
        <Link to={"login"}>
            <button style={{zIndex: '-1'}} className={'sing_in'}>

                                     <span style={{zIndex: '-2'}} className={'Button-inner'}>
                                         Login
                                     </span>
            </button>

        </Link>
    </div>



    <MobileHeader/>




                    <div>

                    </div>

                </div>


            </div>


            <Outlet/>

            <Scrolling/>

            <For/>
            <Cookie/>
        </div>

    );
};

export default Header;