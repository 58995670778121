import React from "react";
import Carousel from 'react-elastic-carousel';
import './swiper.scss';
import Client2 from "../../pages/home/homeImage/Stefano_di_Pasqua.png";
import Client from "../../pages/home/homeImage/Venenzio_di_Bacco.png";
import Client3 from "../../pages/home/homeImage/Beni_Huggel.png";
import Client4 from "../../pages/home/homeImage/img_1.png";
import Client5 from "../../pages/home/homeImage/img_3.png";
import Client6 from "../../pages/home/homeImage/img.png";
import {useTranslation} from "react-i18next";


const breakPoints = [
    {width: 1, itemsToShow: 1},
    {width: 550, itemsToShow: 1},
    {width: 790, itemsToShow: 2},
    {width: 1100, itemsToShow:3},
    {width: 1300, itemsToShow: 4},
    {width: 1800, itemsToShow: 5},
];

const SwipeToSlid = () => {

    const {t} = useTranslation()

    return (
        <>

            <div className="carusel_wrapper">
                <Carousel className={'disp'} itemPadding={[10, 10]}  breakPoints={breakPoints}>
                    <div className={'main_wrapper_carusel'}>

                        <div className={'carusel_tatle'}>
                            <div className={'carusel_p'}>
                                <p>
                                    {t('The technology')}
                                </p>
                            </div>

                            <div className={'carusel_img'}>
                                <div>
                                    <img src={Client} alt={'client'}/>
                                </div>
                                <div className={'carusel_text_client '}>
                                    <h5>Venanzio di Bacco</h5>
                                    <h6> CEO<br/>
                                        Pathé Suisse SA</h6>
                                </div>
                            </div>


                        </div>


                    </div>
                    <div className={'main_wrapper_carusel'}>


                        <div className={'carusel_tatle'}>
                            <div className={'carusel_p'}>
                            <p>
                                {t('A successful')}
                            </p>
                            </div>
                            <div className={'carusel_img'}>
                                <div>
                                    <img src={Client2} alt={'client'}/>
                                </div>
                                <div className={'carusel_text_client '}>
                                    <h5>Stefana di Pasqua</h5>
                                    <h6>CHRO<br/>
                                        LIPO Einrichtungsmärkte AG</h6>
                                </div>
                            </div>


                        </div>
                    </div>
                    <div className={'main_wrapper_carusel'}>


                        <div className={'carusel_tatle'}>
                            <div className={'carusel_p'}>
                                <p>
                                    {t('We had the opportunity')}
                                </p>
                            </div>
                            <div className={'carusel_img'}>
                                <div>
                                    <img src={Client5} alt={'client'}/>
                                </div>
                                <div className={'carusel_text_client '}>
                                    <h5>Fränzi Burkhart</h5>
                                    <h6>Head of Organizational Development
                                        Denner AG
                                    </h6>
                                </div>
                            </div>


                        </div>

                    </div>
                    <div className={'main_wrapper_carusel'}>


                        <div className={'carusel_tatle'}>
                            <div className={'carusel_p'}>
                                <p>
                                    {t('FLOWLE gave')}
                                </p>
                            </div>
                            <div className={'carusel_img'}>
                                <div>
                                    <img src={Client6} alt={'client'}/>
                                </div>
                                <div className={'carusel_text_client '}>
                                    <h5>Dave Heiniger </h5>
                                    <h6>Co-Founder & HR Expert<br/>
                                        Athletes Network</h6>
                                </div>
                            </div>


                        </div>

                    </div>
                    <div className={'main_wrapper_carusel'}>


                        <div className={'carusel_tatle'}>
                            <div className={'carusel_p'}>
                                <p>
                                    {t('Thanks to FLOWLE')}
                                </p>
                            </div>
                            <div className={'carusel_img'}>
                                <div>
                                    <img src={Client4} alt={'client'}/>
                                </div>
                                <div className={'carusel_text_client '}>
                                    <h5>Michael Kubli </h5>
                                    <h6>Co-Founder & CEO<br/>
                                        Kinastic AG
                                    </h6>
                                </div>
                            </div>


                        </div>
                    </div>
                    <div className={'main_wrapper_carusel'}>
                        <div className={'carusel_tatle'}>
                            <div className={'carusel_p'}>
                            <p>
                                {t('When we founded')}
                            </p>
                            </div>
                            <div className={'carusel_img'}>
                                <div>
                                    <img src={Client3} alt={'client'}/>
                                </div>
                                <div className={'carusel_text_client '}>
                                    <h5>Beni Hugger</h5>
                                    <h6>Co-Founder<br/>
                                        Athletes Network</h6>
                                </div>
                            </div>


                        </div>

                    </div>


                </Carousel>
            </div>
        </>
    );
}

export default SwipeToSlid;