import React from 'react';
import {HashLink as Link} from 'react-router-hash-link';
import {MenuAboutAs} from "../MenuItens/MenuAboutAs";
import {useTranslation} from "react-i18next";

const DropDawnAboutAs = () => {
    const {t} = useTranslation()
    return (
        <div>
            <ul className={'dropdawn_menu'}>
                {MenuAboutAs.map((item, index) => {
                    return (
                        <li key={index}>
                            <Link
                                className={item.cName}
                                to={item.href}>
                                {t(item.title)}
                            </Link>

                        </li>
                    )}
                )}
            </ul>
        </div>
    );
};

export default DropDawnAboutAs;