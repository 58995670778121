import React, {useState} from 'react';
import './ControlledCarousel.scss'
import {Carousel} from "react-bootstrap";
import Slade1 from './imageCarousel/img_5.png'
import Slade2 from './imageCarousel/img_6.png'
import Slade3 from './imageCarousel/img.png'
import Slade4 from './imageCarousel/img_1.png'
import AOS from 'aos';
import "aos/dist/aos.css";
import {useTranslation} from "react-i18next";
import { HashLink as Link } from 'react-router-hash-link';

function ControlledCarousel() {

    AOS.init({
        duration: 1500
    });

    const [index, setIndex] = useState(0);

    const handleSelect = (selectedIndex) => {
        setIndex(selectedIndex);
    };
    const {t} = useTranslation()
    return (

        <Carousel id="carousel_1"
                  interval={4000}
                  fade={true}
                  activeIndex={index}
                  onSelect={handleSelect}
        >

            <Carousel.Item>


                <div className={'carousel_wrapper'}>



                        <Carousel.Caption>
                            <div className={'carousel_text'}>
                                <h2>FLOWLE | Culture Data</h2>
                                <p>
                                    {t('Organizational Culture Made Tangible Through Data and KPIs')}
                                </p>
                                <div className={'button_slader'}>
                                    <Link to={"product#Culture"}>
                                        <button className={'button_data'}>
                            <span className={'Button-inner'}>
                       {t('Learn More')}
                               </span>
                                        </button>
                                    </Link>

                                </div>

                            </div>

                            <div className={'block_carusel'}>
                                <div className={'bloc-img'}>
                                    <img className={'slade'} src={Slade1} alt="First slide"/>
                                </div>
                            </div>
                        </Carousel.Caption>
                    </div>



            </Carousel.Item>


            <Carousel.Item>

                <div className={'carousel_wrapper_2'}>




                    <Carousel.Caption>
                        <div className={'carousel_text'}>
                            <h2 style={{color: '#009FBF'}}>FLOWLE | Strategy</h2>

                            <p style={{color: '#FFF6EF'}}>
                                {t('Optimized Culture Efficiency and Business Strategy Implementation')}
                            </p>
                            <div className={'button_slader'}>
                                <Link to={"product#Strategy"}>

                                <button style={{background: '#009FBF'}}>
                            <span className={'Button-inner'}>
                            {t('Learn More')}
                               </span>
                                </button>
                                </Link>

                            </div>
                        </div>
                        <div className={'block_carusel'}>
                            <div className={'bloc-img'}>
                                <img className={'slade_2'} src={Slade2} alt="First slide"/>
                            </div>
                        </div>

                    </Carousel.Caption>
                </div>
            </Carousel.Item>

            <Carousel.Item>


                <div className={'carousel_wrapper_3'}>

                    <Carousel.Caption>
                        <div className={'carousel_text'}>
                            <h2 style={{color: '#1CAFA0'}}>FLOWLE | Recruitment</h2>

                            <p style={{color: '#EDFFF2'}}>
                                {t('Identifying Cultural Fit and Cultural Add as Early as the Recruitment Process')}

                            </p>

                            <div className={'button_slader'}>

                                <Link to={"product#Recruitment"}>

                                <button style={{background: '#1CAFA0'}}>
                            <span className={'Button-inner'}>
                            {t('Learn More')}
                               </span>
                                </button>
                                </Link>
                            </div>
                        </div>
                        <div className={'block_carusel'}>
                            <div className={'bloc-img'}>
                                <img className={'slade_3'} src={Slade3} alt="First slide"/>
                            </div>
                        </div>
                    </Carousel.Caption>
                </div>
            </Carousel.Item>


            <Carousel.Item>
                <div className={'carousel_wrapper_4'}>
                    <Carousel.Caption>
                        <div className={'carousel_text'}>
                            <h2 style={{color: '#7D69A8'}}>FLOWLE | Leadership </h2>
                            <p style={{color: '#EAE0FF'}}>
                                {t('Leadership Development Through Expectation Management')}
                            </p>
                            <div className={'button_slade4'}>

                                <div className={'button_slader'}>

                                    <Link to={"product#Leadership"}>
                                    <button style={{background: '#7D69A8'}}>
                            <span className={'Button-inner'}>
                             {t('Learn More')}
                               </span>
                                    </button>

                                    </Link>

                                </div>
                            </div>

                        </div>
                        <div className={'block_carusel'}>
                            <div className={'bloc-img'}>
                                <img className={'slade_4'} src={Slade4} alt="First slide"/>
                            </div>
                        </div>


                    </Carousel.Caption>
                </div>


            </Carousel.Item>


        </Carousel>

    );
}

export default ControlledCarousel;