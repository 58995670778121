import React, {useRef, useState,  useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import i18next from 'i18next'
import cookies from 'js-cookie'
import './language.css'
import {Dropdown, Form} from "react-bootstrap";
import {
    Menu,MenuButton,
    ControlledMenu,
    MenuItem,
    useMenuState
} from '@szhsin/react-menu';
import '@szhsin/react-menu/dist/index.css';
import '@szhsin/react-menu/dist/transitions/slide.css';
import { VscGlobe } from "react-icons/vsc";
import {faGlobe} from "@fortawesome/free-solid-svg-icons";
import {faLinkedinIn} from "@fortawesome/free-brands-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import i18n from "i18next";

const Language = () => {


  const [countries] = useState([
        {code: 'en', title: 'English',},
        {code: 'de', title: 'Deutsch',},

    ]);

  const currentLanguageCode = cookies.get('i18next') || 'en'
    const {t} = useTranslation()


    const [toggleContents, setToggleContents] = useState('English');



    const [selectedCountry, setSelectedCountry] = useState();

    const ref = useRef(null);
    const [menuProps, toggleMenu] = useMenuState({ transition: true });

    const [language, setLanguage] = useState('en-US');
    const [languageList, setLanguageList] = useState([]);





    return (

        <div className={'header_icons_right'}>


            <div className={'container'}>


                <Dropdown size="sm"
                        onSelect={eventKey => {

                            const { code, title } = countries.find(({ code }) => eventKey === code);
                            setSelectedCountry(eventKey);
                            setToggleContents(<>{title}</>);
                        }}>

                        <Dropdown.Toggle variant="secondary" >
                            <FontAwesomeIcon style={{marginRight: '5px'}}  icon={faGlobe}/>
                           {toggleContents}
                        </Dropdown.Toggle>

                        <Dropdown.Menu   className="text-center"  style={{ width:'140px',zIndex:'100'}}>
                            {countries.map(({ code, title }) => (
                                <Dropdown.Item  style={{}} key={code} eventKey={code}

                                               onClick={() => {
                                                   i18next.changeLanguage(code)
                                               }}
                                               disabled={code === currentLanguageCode}>
                                    {title}
                                </Dropdown.Item>
                            ))}
                        </Dropdown.Menu>
                    </Dropdown>

                </div>




        </div>
    );
};

export default Language;