import React, {useRef} from 'react';
import {HashLink as Link} from 'react-router-hash-link';
import {AiOutlineDown} from "react-icons/ai";
import DropDawnFlovle from "./DecstopNav/DropDawnFlovle";
import DropDawnProduct from "./DecstopNav/DropDawnProduct";
import DropDawnAboutAs from "./DecstopNav/DropDawnAboutAs";
import DropDawnBenefits from "./DecstopNav/DropDawnBenefits";
import {useTranslation} from "react-i18next";
import {
    ControlledMenu,
    MenuItem,
    useMenuState
} from '@szhsin/react-menu';
import '@szhsin/react-menu/dist/index.css';
import '@szhsin/react-menu/dist/transitions/slide.css';


const NavLinks = () => {


    const ref = useRef(null);
    const [menuProps, toggleMenu] = useMenuState({transition: true});

    const refProduct = useRef(null);
    const [menuProduct, toggleMenuProduct] = useMenuState({transition: true});

    const refBenefits = useRef(null);
    const [menuBenefits, toggleMenuBenefits] = useMenuState({transition: true});


    const refAboutAs = useRef(null);
    const [menuAboutAs, toggleMenuAboutAs] = useMenuState({transition: true});
    const {t} = useTranslation()


    return (

        <div className={'header_right'}>


            <ul className={'nav'}>

                <li ref={ref} onMouseEnter={() => toggleMenu(true)}>
                    <div>

                        <Link to={"whyFlowle"}>

                        <span>
                            {t('Why FLOWLE')}
                            <AiOutlineDown/>
                            </span>

                        </Link>
                    </div>
                    <div className="dropdown-content">
                        <ControlledMenu {...menuProps} anchorRef={ref}
                                        onMouseLeave={() => toggleMenu(false)}
                                        onClose={() => toggleMenu(false)}>
                            <MenuItem>
                                <DropDawnFlovle/></MenuItem>

                        </ControlledMenu>
                    </div>


                </li>
                <li>
                    <div ref={refProduct} onMouseEnter={() => toggleMenuProduct(true)}>
                        <Link to={"product"}>

                        <span>
                            {t('Product')}
                            <AiOutlineDown/>
                        </span>

                        </Link>
                    </div>

                    <div className="dropdown-content">
                        <ControlledMenu {...menuProduct} anchorRef={refProduct}
                                        onMouseLeave={() => toggleMenuProduct(false)}
                                        onClose={() => toggleMenuProduct(false)}>
                            <MenuItem>
                                <DropDawnProduct/></MenuItem>

                        </ControlledMenu>
                    </div>
                </li>

                <li>
                    <div ref={refBenefits} onMouseEnter={() => toggleMenuBenefits(true)}>
                        <Link to="benefits">

                        <span>
                             {t('Benefits')}
                            <AiOutlineDown/>
                        </span>

                        </Link>
                    </div>

                    <div className="dropdown-content">
                        <ControlledMenu {...menuBenefits} anchorRef={refBenefits}
                                        onMouseLeave={() => toggleMenuBenefits(false)}
                                        onClose={() => toggleMenuBenefits(false)}>
                            <MenuItem>
                                <DropDawnBenefits/></MenuItem>

                        </ControlledMenu>
                    </div>
                </li>

                <li>
                    <div ref={refAboutAs} onMouseEnter={() => toggleMenuAboutAs(true)}>
                        <Link to="aboutUs">

                        <span>
                              {t('About Us')}

                            <AiOutlineDown/>
                        </span>

                        </Link>
                    </div>

                    <div className="dropdown-content">
                        <ControlledMenu {...menuAboutAs} anchorRef={refAboutAs}
                                        onMouseLeave={() => toggleMenuAboutAs(false)}
                                        onClose={() => toggleMenuAboutAs(false)}>
                            <MenuItem>
                                <DropDawnAboutAs/></MenuItem>

                        </ControlledMenu>
                    </div>
                </li>


            </ul>

        </div>
    );
};

export default NavLinks;