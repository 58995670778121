import React, {useState} from 'react';
import {BsFilterRight} from "react-icons/bs";
import {VscChromeClose} from "react-icons/vsc";
import NavLinkMobile from "./NavLinkMobile";





const MobileHeader = () => {
    const [open, setOpen] = useState(false)


    const hamburgerIcon = <VscChromeClose size={'35px'}
                                         className={'mobile-menu-icon_close'}
    onClick={() => setOpen((!open))}/>

    const closeIcon = <BsFilterRight size={'50px'}

                                          onClick={() => setOpen((!open))}/>


    const closeMobileMenu = () => setOpen(false)

    return (
        <div className={'display_mobile'}>
                {open ?  hamburgerIcon : closeIcon}

            {open &&  <NavLinkMobile isMobile={true} closeMobileMenu={closeMobileMenu}/>}


            </div>



    );
};

export default MobileHeader;