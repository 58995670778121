import React, {useEffect, useRef, useState} from 'react';
import Flo from "../footer/img.png";
import {motion} from "framer-motion"
import {AiOutlineDown, AiOutlineRight,} from "react-icons/ai";
import Dropdawn from "./Dropdawn";
import DropdawnProduct from "./DropdawnProduct";
import DropDawnBenefits from "./DropDawnBenefits";
import DropdawnAboutAs from "./DropdawnAboutAs";
import {useTranslation} from "react-i18next";
import {HashLink as Link} from 'react-router-hash-link';



const NavLinkMobile = ({closeMobileMenu}) => {





    let mobileRef = useRef(null)
    const [dropDawn, setDropDawn] = useState(false)


    useEffect(() =>{
        let mayHandler = (e) => {
            if ( !mobileRef.current.contains(e.target)){
                setDropDawn(false)
            }
        }
        document.addEventListener('mousedown', mayHandler) ;
          return() => {
              document.removeEventListener('mousedown', mayHandler);
          };

    });


    let mobileProduct = useRef(null)
    const [dropDawnProduct, setDropDawnProduct] = useState(false)

    useEffect(() =>{
        let mayHandlerProduct = (e) => {
            if (!mobileProduct.current.contains(e.target)){
                setDropDawnProduct(false)
            }
        }
        document.addEventListener('mousedown', mayHandlerProduct) ;
        return() => {
            document.removeEventListener('mousedown', mayHandlerProduct);
        };

    });


    let mobileBenefits = useRef(null)
    const [dropDawnBenefits, setDropDawnBenefits] = useState(false)

    useEffect(() =>{
        let mayHandlerBenefits = (e) => {
            if (!mobileBenefits.current.contains(e.target)){
                setDropDawnBenefits(false)
            }
        }
        document.addEventListener('mousedown', mayHandlerBenefits) ;
        return() => {
            document.removeEventListener('mousedown', mayHandlerBenefits);
        };

    });



    const [dropDawnAs, setDropDawnAs] = useState(false)

    let mobileDawnAs = useRef(null)

    useEffect(() =>{
        let mayHandlerDawnAs = (e) => {
            if (!mobileDawnAs.current.contains(e.target)){
                setDropDawnAs(false)
            }
        }
        document.addEventListener('mousedown', mayHandlerDawnAs) ;
        return() => {
            document.removeEventListener('mousedown', mayHandlerDawnAs);
        };

    });







    const dropIcon = <AiOutlineRight size={'25px'}
                                     className={'mobile-menu-drowen_close'}
                                     onClick={() => setDropDawn((!dropDawn))}/>

    const closedDrop = <AiOutlineDown size={'25px'}
                                      className={'mobile-menu-drowen'}
                                      onClick={() => setDropDawn((!dropDawn))}/>



    const dropIconProduct = <AiOutlineRight size={'25px'}
                                     className={'mobile-menu-drowen_close'}
                                     onClick={() => setDropDawnProduct((!dropDawnProduct))}/>

    const closedDropProduct = <AiOutlineDown size={'25px'}
                                      className={'mobile-menu-drowen'}
                                      onClick={() => setDropDawnProduct((!dropDawnProduct))}/>

    const dropIconBenefits = <AiOutlineRight size={'25px'}
                                            className={'mobile-menu-drowen_close'}
                                            onClick={() => setDropDawnBenefits((!dropDawnBenefits))}/>

    const closedDropBenefits = <AiOutlineDown size={'25px'}
                                             className={'mobile-menu-drowen'}
                                             onClick={() => setDropDawnBenefits((!dropDawnBenefits))}/>




    const dropIconAs = <AiOutlineRight size={'25px'}
                                            className={'mobile-menu-drowen_close'}
                                            onClick={() =>setDropDawnAs((!dropDawnAs))}/>

    const closedDropAs = <AiOutlineDown size={'25px'}
                                             className={'mobile-menu-drowen'}
                                             onClick={() => setDropDawnAs((!dropDawnAs))}/>


    const animationForm = {opacity: 0, y: -40}
    const animationTo = {opacity: 1, y: 0}




    const {t} = useTranslation()

    return (
        <div className={'mobile_navlinc'}>

            <div className={'logotip'}>

                <motion.img initial={animationForm}
                            animate={animationTo}
                            transition={{delay: 0.10}}
                            src={Flo} alt={'flo'}/>
            </div>

            <ul>
                <motion.div initial={animationForm}
                            animate={animationTo}
                            transition={{delay: 0.20}}>
                    <li ref={mobileRef}>

                        <Link to={"whyFlowle"}>

                            <div className={'dropdown'}>
                        <span> {t('Why FLOWLE')}
                        {dropDawn ? closedDrop : dropIcon}</span>
                            </div>
                        </Link>
                        {dropDawn && <Dropdawn isMobile={true} closeMobileMenu={closeMobileMenu}/>}

                    </li>

                </motion.div>



                    <motion.div
                        initial={animationForm}
                        animate={animationTo}
                        transition={{delay: 0.30}}>

                        <li ref={mobileProduct}>
                            <Link to="product">
                                <div className={'dropdown'}>
                        <span>
                            {t('Product')}
                            {dropDawnProduct ? closedDropProduct : dropIconProduct}</span>
                                </div>
                            </Link>
                            {dropDawnProduct && <DropdawnProduct isMobile={true} closeMobileMenu={closeMobileMenu}/>}

                        </li>
                    </motion.div>


                <motion.div
                    initial={animationForm}
                    animate={animationTo}
                    transition={{delay: 0.30}}>

                    <li ref={mobileBenefits}>
                        <Link to="benefits">
                            <div className={'dropdown'}>
                        <span>
                            {t('Benefits')}
                            {dropDawnBenefits ? closedDropBenefits : dropIconBenefits}</span>
                            </div>
                        </Link>
                        {dropDawnBenefits && <DropDawnBenefits isMobile={true} closeMobileMenu={closeMobileMenu}/>}

                    </li>
                </motion.div>

                    <motion.div initial={animationForm}
                                animate={animationTo}
                                transition={{delay: 0.40}}>
                        <li ref={mobileDawnAs}>
                            <Link to="aboutUs">
                                <div className={'dropdown'}>
                        <span>
                            {t('About Us')}
                            {dropDawnAs ? closedDropAs : dropIconAs}</span>
                                </div>
                            </Link>
                            {dropDawnAs && <DropdawnAboutAs isMobile={true} closeMobileMenu={closeMobileMenu}/>}
                        </li>
                    </motion.div>



            </ul>
        </div>
    );
};

export default NavLinkMobile;