import React, {useEffect, useRef } from 'react';
import '.././Demo/ReguestDemo.scss'
import {useForm} from "react-hook-form";
import emailjs from "@emailjs/browser";
import {Link} from "react-router-dom";
import {AiOutlineLeft} from "react-icons/ai";
import {useNavigate} from "react-router";
import {useTranslation} from "react-i18next";
import Flo from "../../components/footer/img.png";



const ContactAs = () => {


    const nav = useNavigate();

    function ScrollToTopOnMount() {
        useEffect(() => {
            window.scrollTo(0, 0);
        }, []);

        return null;
    }


    const form = useRef();






    function sendEmail(data, e) {
        e.preventDefault();
        emailjs
            .sendForm('service_2vkaat9', 'template_klgawjn', e.target, 'user_7fdrdAgJSGYSdvUh1dWK5')



            .then((result) => {
                console.log(result.text);
            }, (error) => {
                console.log(error.text);
            });
        e.target.reset()

        nav("thanksContact");


        reset()
    }


    const {
        register,
        handleSubmit,
        formState: {errors},
        reset
    } = useForm({
        mode: "onBlur",
        defaultValues: {
            FirstName : "",
            LastName: "",
            email: "",
            message: "",
            checkbox:"",

        }
    });





    const {t} = useTranslation()

    return (


        <div className={'contact_wrapper_login'}>
            <div className={'login_back'}>
                <ScrollToTopOnMount />
                <Link to="/">
                    <div className={'back'}>
                        <p> <AiOutlineLeft style={{color:'white',marginRight:"10px"}}/>
                            {t('Back to: Flowle.com')}
                        </p>
                    </div>
                </Link>

            </div>
            <div className={'wrapper_forms'}>

            <div  className={'contact_form'} >
                <div className={'form-tattle'}>
                    <h2>
                        {t('Contact Form')}

                    </h2>
                    <p>
                        {t('Whether you are looking')}

                    </p>
                    <p style={{fontWeight: '500',
                        fontSize: '14px',
                        lineHeight: '140%'}}>
                        {t('Fill out the form')}
                    </p>

                </div>


                <form className={'contact_colum'} ref={form} onSubmit={handleSubmit(sendEmail)} >


                    <div className="row">

                        <input type="text"
                               placeholder={t('First Name')}
                               {...register("FirstName", {
                                   required: true,
                                   pattern: /^[A-Za-z]+$/i,
                               })}
                        />

                        {errors?.FirstName?.type === "required" && <p className={'errors'}>{t('This field is required')}</p>}
                        {errors?.FirstName?.type === "pattern" && (<p className={'errors'}>{t('Alphabetical characters only')}</p>)}

                        <input type="text"
                               placeholder={t('Last Name')}
                               {...register("LastName", {
                                   required: true,
                                   pattern: /^[A-Za-z]+$/i,
                               })}
                        />

                        {errors?.LastName?.type === "required" && <p className={'errors'}>{t('This field is required')}</p>}

                        {errors?.LastName?.type === "pattern" && (<p className={'errors'}>{t('Alphabetical characters only')}</p>)}



                        <input type="text"
                               placeholder={t('E-Mail')}
                               {...register("email", {
                                   required: 'This is required',
                                   pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                               })}
                        />
                        {errors?.email?.type === "required" && <p className={'errors'}>{t('This field is required')}</p>}
                        {errors?.email?.type === "pattern" && (<p className={'errors'}>{t('Enter a valid email address')}</p>)}

                    </div>

                    <textarea className="form-control"
                              placeholder={t('Message')}
                              style={{height: 150, margin: 0}}
                              {...register("message", {
                                  required: true,

                              })}/>

                    {errors?.message?.type === "required" &&
                    <p style={{fontWeight: '30',
                        fontSize: '20px',
                        lineHeight: '134.69%',
                        letterSpacing: '0.03em',
                        color:'red'}}>
                        {t('Please write a short message...')}
                       </p>}


                    <div className="formname">

                        <input id="checkbox"
                               type="checkbox"
                               {...register("checkbox", {
                                   required: true,})}
                        />
                        {`errors`?.checkbox?.type === "required" && <p className={'errors'}>{t('This field is required')}</p>}

                        <label htmlFor={"checkbox"} style={{ marginTop: '20px'}}>
                            <p style={{fontWeight: '300',
                                fontSize: '10px',
                                lineHeight: '134.69%',
                                letterSpacing: '0.03em',
                                textAlign: 'start'}}>
                                {t('I have read the')}
                                {' '}

                                <Link to={'/policies'}>
                                    <u>{t('Privacy Policy')}</u>
                                </Link>
                                {t('and agree to the collection')}

                            </p>


                        </label>
                    </div>


                    <button  style={{marginRight: '7px', marginLeft: '0', marginTop: '20px'}}
                            className={'sing_in'} >

                            <span style={{border: '1px solid transparent',
                                borderRadius: '6px'}} className={'Button-inner'}>
                                  <div style={{marginLeft: '120px'}}>
                                      {t('SUBMIT')}
                                     </div>
                            </span>

                    </button>

                </form>




            </div>
            </div>

            <div className={'logo_address'}>


                <img src={Flo} alt={'flo'}/>


                <p>© 2022 by GROWITHY AG

                    <Link to={'/policies'}>


                        <u> Privacy& Cookie Policy</u>


                    </Link>
                </p>


            </div>
        </div>
    );
};

export default ContactAs;
