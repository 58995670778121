import React from "react";
import './index.scss'
import {Route, Routes} from "react-router-dom";
import Home from "./pages/home/Home";
import Product from "./pages/product/Product";
import WhyFlowle from "./pages/whyFlowle/WhyFlowle";
import Header from "./components/header/Header";
import Login from "./pages/login/Login";
import ContactAs from "./pages/contactAs/ContactAs";
import AboutAs from "./pages/aboutUs/AboutAS";
import Benefits from "./pages/Benefits/Benefits";
import Policy from "./pages/Policy/Policy";
import ReguestDemo from "./pages/Demo/ReguestDemo";
import ThanksReaching from "./pages/Thanks/ThanksReaching";
import ThanksContact from "./pages/Thanks/ThanksContact";






class App extends React.Component {



    render() {




        return (


            <Routes>

                <Route path='/' element={<Header/>}>
                    <Route index element={<Home/>}/>
                    <Route path="whyFlowle" element={<WhyFlowle/>}/>
                    <Route path="aboutUs" element={<AboutAs/>}/>
                    <Route path="product" element={<Product/>}/>
                    <Route path="benefits" element={<Benefits/>}/>
                    <Route path="policies" element={<Policy/>}/>

                </Route>
                      <Route path="product/demo" element={<ReguestDemo/>}/>
                      <Route path="contactUs" element={<ContactAs/>}/>
                      <Route path="login" element={<Login/>}/>
                      <Route path="product/demo/thanksReaching" element={<ThanksReaching/>}/>
                      <Route path="contactUs/thanksContact" element={<ThanksContact/>}/>


            </Routes>


        );
    }
}

export default App;
