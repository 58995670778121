import React, {useEffect} from 'react';
import './WhyFlowle.scss';
import Toxic from './imageWhyFlowle/img_1.png';
import Staff from './imageWhyFlowle/img_2.png';
import Expectation from './imageWhyFlowle/img_3.png';
import Spider from './imageWhyFlowle/Backgroung_img_1.png';
import Costs from './imageWhyFlowle/img_11.png';
import Nam_1 from './imageWhyFlowle/img_13.png';
import Nam_2 from './imageWhyFlowle/img_9.png';
import Nam_3 from './imageWhyFlowle/img_7.png';
import CompHalp from './imageWhyFlowle/img_5.png'
import Spider_Left from './imageWhyFlowle/img.png'
import Boxs from "./Boxs";
import AOS from "aos";
import {useTranslation} from "react-i18next";
import {Helmet} from "react-helmet";



const WhyFlowle = () => {

    function ScrollToTopOnMount() {
        useEffect(() => {
            window.scrollTo(0, 0);
        }, []);

        return null;
    }




    useEffect(() => {
        AOS.init({
            duration: 2000,
            disable: window.innerWidth < 1300,
        });
        AOS.refresh();
    }, []);

    const {t} = useTranslation()
    return (
        <div className={'home_wrapper'}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Why Flowle | Flowle.com</title>

            </Helmet>
            <ScrollToTopOnMount />
            <section id='Areas' className={'why_flowle'}>
                <div className={'GetData_wrapper'}>
                    <div className={'get_Data'}>
                        <div className={'get_Data_text'}>
                            <h2 data-aos="fade-right" >
                                {t('FLOWLE – Get Data. Get Insights.')}
                                </h2>

                            <hr/>
                            <h4 data-aos="fade-left">
                                {t('Does your')}</h4>
                            <p data-aos="fade-left">
                                {t('Cultural dysfunction')}
                                <br/>
                                {t('That\'s why')}

                            </p>
                        </div>

                    </div>

                </div>

                <div>

                    <div className={'liegen_Probleme'}>

                        <div className={'wrapper_problems'}>
                            <div className={'problems'}>
                                <h2>
                                    {t('the Problems')}</h2>
                            </div>

                            <div className={'problems_box_wrapper'}>
                                <div className={'problems_box'}>

                                    <img src={Toxic} alt={'Turnover'}/>
                                    <div className={'Toxic'}>
                                        <h3>
                                            {t('Toxic')}

                                        </h3>
                                    </div>

                                    <p>

                                        {t('Toxic workplace')}

                                    </p>


                                </div>
                                <div className={'problems_box'}>
                                    <img src={Staff} alt={'Turnover'}/>
                                    <div className={'Toxic'}>
                                        <h3>

                                            {t('Culture-Related')}
                                        </h3>
                                    </div>

                                    <p>
                                        {t('27% of employees')}
                                    </p>
                                </div>
                                <div className={'problems_box'}>
                                    <img src={Expectation} alt={'Expectation'}/>
                                    <div className={'Toxic'}>
                                        <h3>

                                            {t('Perception')}
                                        </h3>
                                    </div>

                                    <p>
                                        {t('72% of executives')}
                                    </p>
                                </div>
                            </div>
                            <div className={'problems'}>
                                <p>
                                    {t('*Quellen')}
                                    {t('The Culture Economy')}
                                    </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section id='Flowle' className={'why_flowle'}>

                <div className={'Help'}>
                    <img className={'spader_non'} src={Spider} alt={'Spider'}/>
                    <div className={'Help_text'}>

                        <h2 data-aos="zoom-out-up" >{t('How Can')}</h2>

                        <h3>
                            {t('The solution')}</h3>
                        <p>
                            {t('FLOWLE enables you')}
                        </p>
                    </div>
                    <div className={'box_wrapper_help'}>

                        <div className={'box_help'}>
                            <img src={Costs} alt={'Relationships'}/>
                            <div className={'nam'}>
                                <img src={Nam_1} alt={'number'}/>
                            </div>
                            <div className={'Lower_Costs'}>
                                <h3>
                                    {t('Lower Costs')}</h3>
                            </div>

                            <p>

                                {t('With FLOWLE, your')}</p>
                        </div>

                        <div className={'box_help'}>
                            <img src={Costs} alt={'Relationships'}/>
                            <div className={'nam'}>
                                <img src={Nam_3} alt={'number'}/>
                            </div>
                            <div className={'Lower_Costs'}>
                                <h3>
                                    {t('Improved Relationships')}
                                    </h3>
                            </div>

    <p>
        {t('FLOWLE helps identify')}
       </p>


                        </div>

                        <div className={'box_help'}>
                            <img src={Costs} alt={'Performance'}/>
                            <div className={'nam'}>
                                <img src={Nam_2} alt={'number'}/>
                            </div>
                            <div className={'Lower_Costs'}>

                                    <h3>
                                        {t('Increased Performance')}
                                    </h3>


                            </div>

                            <p>
                                {t('FLOWLE increases the efficiency')}
                                 </p>
                        </div>
                    </div>

                    <div className={'What_Apart'}>
                        <div   className={'comp_help'}>
                            <img src={CompHalp} alt={'comp_help'}/>
                        </div>

                        <div className={'Apart_text'}>
                            <h2 data-aos="fade-down"
                                data-aos-easing="linear"
                                data-aos-duration="1500">
                                {t('What Sets')}

                            </h2>
                            <hr/>
                            <h3>
                                {t('is the first')}

                            </h3>
                            <p>
                                {t('The disruptive')}

                            </p>
                        </div>

                        <div className={'SpiderLeft'}>
                            <img src={Spider_Left} alt={'SpiderLeft'}/>
                        </div>
                    </div>


                </div>
            </section>

            <section id='Solution' className={'hy_flowle_endw'}>

    <div className={'Unique_wrapper'}>
        <Boxs/>

</div>






            </section>

        </div>
    );
};

export default WhyFlowle;