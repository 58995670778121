import React, {useEffect} from 'react';
import './Benefits.scss'
import Profit_1 from './imageBenefits/img_4.png'
import Profit_2 from './imageBenefits/img_5.png'
import Profit_3 from './imageBenefits/img_6.png'
import Profit_4 from './imageBenefits/img_7.png'
import Profit_5 from './imageBenefits/img_8.png'
import Profit_6 from './imageBenefits/img_9.png'
import Profit_7 from './imageBenefits/img_10.png'
import Profit_8 from './imageBenefits/img_11.png'
import FieldsImg from './imageBenefits/img_14.png'
import AOS from "aos";
import {useTranslation} from "react-i18next";
import {Helmet} from "react-helmet";


const Benefits = () => {
    function ScrollToTopOnMount() {
        useEffect(() => {
            window.scrollTo(0, 0);
        }, []);

        return null;
    }

    useEffect(() => {
        AOS.init({
            duration: 2000,
            disable: window.innerWidth < 1300,
        });
        AOS.refresh();
    }, []);

    const {t} = useTranslation()

    return (
        <div className={'home_wrapper'}>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>Benefits | Flowle.com</title>

            </Helmet>
            <ScrollToTopOnMount/>
            <section id='Fields' className={'home_benefits'}>
                <div className={'benefits_background'}>
                    <div className={'Fields_wrapper'}>
                        <div className={'Fields_Data'}>
                            <div className={'Fields_text'}>

                                <h2 data-aos="fade-right">
                                    {t('Fields of Application')}
                                </h2>

                                <hr/>
                                <p data-aos="fade-left">
                                    {t('Use your organizational')}
                                    <br/>
                                    {t('FLOWLE is versatile')}
                                </p>
                            </div>
                            <div className={'Fields_img'}>
                                <img src={FieldsImg} alt={'Fields_img'}/>
                            </div>
                        </div>
                    </div>


                    <div className={'benefits_wrapper_box'}>
                        <div className={'boxs_center'}>
                            <div className={'benefits_box'}>

                                <h3>
                                    {t('Culture & Behavior Analytics')}
                                </h3>


                                <p>{t('What do Status Quo')} </p>
                                <p>{t('Where do urgent')}</p>


                            </div>

                            <div className={'benefits_box'}>

                                <h3>
                                    {t('Recruitment Support')}
                                </h3>




                                <p>  {t('Who fits into')} </p>
                                <p> {t('How do I find the')}</p>
                                <p> {t('How can I increase employee')}</p>


                            </div>

                            <div className={'benefits_box'}>

                                <h3>
                                    {t('Employee Satisfaction')}
                                </h3>


                                <p>{t('How happy are the employees')} </p>
                                <p>{t('Are there gaps between')}</p>

                            </div>


                            <div className={'benefits_box'}>
                                <h3>
                                    {t('Leadership')}
                                </h3>

                                <p>{t('How do I proactively')}</p>
                                <p> {t('How do I get')}</p>



                            </div>


                            <div className={'benefits_box'}>
                                <h3>
                                    {t('Strategy Alignment &')}
                                </h3>


                                <p>{t('How do I create the culture')}</p>
                                <p> {t('Are the right people')}</p>



                            </div>

                            <div className={'benefits_box'}>
                                <h3>
                                    {t('M&A Support')}
                                </h3>
                                <p>{t('Which cultures meet')}</p>
                                <p>{t('Can the new organization')}</p>

                            </div>
                        </div>



                    </div>


                </div>


            </section>


            <section id='Profit' className={'home_benefits_2'}>

                <div className={'Profit_wrapper'}>
                    <div className={'Profit_margin'}>
                        <div data-aos="fade-up"
                             data-aos-duration="3000"
                             className={'Profit_wrapper_text'}>
                            <h2>
                                {t('How You Profit')}

                            </h2>
                            <hr/>
                            <p className={'Profit_wrapper_text'}>
                                {t('FLOWLE helps you build a culture')}
                            </p>
                        </div>
                    </div>

                </div>


                <div className={'Profit_boxes'}>


                    <div className={'Profit_box'}>

                        <img data-aos="flip-down"
                             src={Profit_1} alt={'Profit_1'}/>
                        <h3>
                            {t('Identify &')}

                        </h3>
                        <p>
                            {t('The culture scan')}

                        </p>
                    </div>

                    <div className={'Profit_box'}>

                        <img data-aos="flip-down"
                             src={Profit_2} alt={'Profit_2'}/>
                        <h3>
                            {t('Lower Costs_2')}

                        </h3>
                        <p>
                            {t('Employees who')}

                        </p>
                    </div>


                    <div className={'Profit_box'}>

                        <img data-aos="flip-down"
                             src={Profit_3} alt={'Profit_3'}/>

                        <h3>
                            {t('Improve Performance')}

                        </h3>
                        <p>
                            {t('The execution of')}

                        </p>
                    </div>


                    <div className={'Profit_box'}>

                        <img data-aos="flip-down"
                             src={Profit_4} alt={'Profit_4'}/>

                        <h3>
                            {t('Enhance Relationships')}

                        </h3>
                        <p>
                            {t('Closing perception')}

                        </p>
                    </div>


                    <div className={'Profit_box'}>

                        <img data-aos="flip-up"
                             src={Profit_5} alt={'Profit_5'}/>

                        <h3>
                            {t('Boost Engagement')}

                        </h3>

                        <p>
                            {t('Once you understand your culture')}

                        </p>
                    </div>

                    <div className={'Profit_box'}>

                        <img data-aos="flip-up"
                             src={Profit_6} alt={'Profit_6'}/>

                        <h3>
                            {t('Monitor Success')}

                        </h3>
                        <p>
                            {t('Measure the success of projects')}

                        </p>
                    </div>

                    <div className={'Profit_box'}>
                        <div>
                            <img data-aos="flip-up"
                                 src={Profit_7} alt={'Profit_7'}/>
                        </div>


                        <h3>
                            {t('Save Time & Resources')}

                        </h3>


                        <p>
                            {t('An efficient culture makes your')}

                        </p>
                    </div>
                    <div className={'Profit_box'}>

                        <img data-aos="flip-up"
                             src={Profit_8} alt={'Profit_8'}/>

                        <h3>
                            {t('Leverage & Foster Strengths')}

                        </h3>
                        <p>
                            {t('You can use the data of your')}

                        </p>
                    </div>
                </div>


            </section>


        </div>
    );
};

export default Benefits;