import React, {useEffect} from 'react';
import AOS from "aos";
import {useTranslation} from "react-i18next";


const Boxs = () => {

    useEffect(() => {
        AOS.init({
            duration: 2000,
            disable: window.innerWidth < 768,
        });
        AOS.refresh();
    }, []);

    const {t} = useTranslation()
    return (
        <div className={'Unique_boxes'}>


                    <div  data-aos="zoom-out-up"  className={'Unique_box'}>

                        <h3>
                            {t('Unique Features')}

                        </h3>
                        <p>
                            {t('FLOWLE provides KPIs')}

                        </p>

                    </div>

            <div  data-aos="zoom-out-up"  className={'Unique_box'}>

                <h3>
                    {t('Innovative Survey')}

                </h3>
                <p>
                    {t('A disruptive')}
                   
                </p>

            </div>
            <div  data-aos="zoom-out-up"  className={'Unique_box'}>

                <h3>

                    {t('Intuitive Dashboard')}

                </h3>
                <p>
                    {t('Using 6 KPIs')}
                </p>

            </div>
            <div  data-aos="zoom-out-up"  className={'Unique_box'}>

                <h3>
                    {t('Cloud-Based')}

                </h3>
                <p>
                    {t('platform')}

                </p>

            </div>
            <div  data-aos="zoom-out-up"  className={'Unique_box'}>

                <h3>
                    {t('Ecosystem Integration')}

                </h3>
                <p>
                    {t('FLOWLE can be easily')}

                </p>

            </div>
            <div  data-aos="zoom-out-up"  className={'Unique_box'}>

                <h3>
                    {t('Flexible Subscription Model')}
                    
                </h3>
                <p>
                    {t('We offer 4 licenses.')}

                </p>

            </div>

        </div>
    );
};

export default Boxs;