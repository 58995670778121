import React, {useEffect} from 'react';
import './AboutAs.scss'
import Li from './Images_face/img.png'
import LiGf from './Images_face/li.gif'
import Fab from './Images_face/img_1.png'
import FabGf from './Images_face/fab.gif'
import Fran from './Images_face/img_2.png'
import FranGf from './Images_face/franchesko.gif'
import Car from './Images_face/img_3.png'
import CarGf from './Images_face/Flamme_Olympique_anime.gif'
import Kris from './Images_face/img_4.png'
import KrisGf from './Images_face/kris.gif'
import Dam from './Images_face/img_5.png'
import DamGf from './Images_face/5167.gif'
import Stef from './Images_face/img_6.png'
import StefGf from './Images_face/stef.gif'
import Sand from './Images_face/img_7.png'
import SandGf from './Images_face/5216.gif'
import Alm from './Images_face/img_8.png'
import AlmGf from './Images_face/5218.gif'
import Rio from './Images_face/5219.png'
import RioGf from './Images_face/Rio.gif'
import Story from './Images_face/img_28.png'
import lin from './Images_face/img_14.png'
import AOS from "aos";
import {useTranslation} from "react-i18next";
import Milestones from './Images_face/img_21.png'
import Milest_1 from './Images_face/img_23.png'
import Milest_2 from './Images_face/img_22.png'
import kreativ from './Images_face/img_24.png'
import etwas from './Images_face/img_25.png'
import zeigen from './Images_face/img_26.png'
import starke from './Images_face/img_27.png'
import Graf_2 from './Images_face/img_15.png'
import {ImQuotesLeft} from "@react-icons/all-files/im/ImQuotesLeft";
import {ImQuotesRight} from "@react-icons/all-files/im/ImQuotesRight";
import CountUp from "react-countup";
import {Helmet} from "react-helmet";




const AboutAs = () => {





    useEffect(() => {
        AOS.init({
            duration: 2000,
            disable: window.innerWidth < 1300,
        });
        AOS.refresh();
    }, []);


    function ScrollToTopOnMount() {
        useEffect(() => {
            window.scrollTo(0, 0);
        }, []);

        return null;
    }

    const {t} = useTranslation()

    return (
        <div className={'home_wrapper'}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Adout Us | Flowle.com</title>

            </Helmet>
            <ScrollToTopOnMount/>
            <section id='Mission' className={'home_Mission'}>
                <div className={'Mission'}>
                    <div className={'Mission_text'}>
                        <h2 data-aos="fade-right">
                            {t('What We Stand For')}


                        </h2>
                        <hr/>

                        <p className={'next'} data-aos="fade-left">
                            {t('we aim to empower')}
                            <br/>
                            {t('Dies tun wir')}
                        </p>
                        <p data-aos="fade-right">
                            {t('you have a team of highly')}

                        </p>

                    </div>
                    <div className={'Mision_img'}>
                        <img src={Graf_2} alt={'Grafik'}/>
                    </div>

                </div>
            </section>
            <section id='Story' className={'home_AboutAs'}>
                <div className={'Story'}>
                    <img src={Story} alt={'Story'}/>
                    <hr/>
                </div>

                <div className={'Story_wrapper_text'}>
                    <div className={'Story_text'}>
                        <div className={'text_Almost'}>
                            <h2 data-aos="fade-up"
                                data-aos-duration="3000">
                                {t('The FLOWLE Story')}

                                <hr/>
                            </h2>

                            <p>
                                {t('Almost everyone has experienced')}


                            </p>
                        </div>

                        <h5 data-aos="fade-up"
                            data-aos-duration="3000">
                            {t('Founder & CEO')}

                        </h5>

                    </div>

                </div>


            </section>
            <section id='Meet' className={'home_ourStory'}>
                <div className={"heading-primary"}>
                    <div className={'Meet_text'}>
                        <h2 data-aos="flip-up">
                            {t('Meet The Team')}
                        </h2>
                        <hr/>
                        <p>
                            {t('Behind FLOWLE there is')}

                        </p>
                    </div>


                </div>


                <div className={'piple_blokc'}>

                    <div className="scene">
                        <div>


                            <div className="team-card">
                                <div className="card-front">
                                    <img src={Fab} alt={'Fab'}/>
                                </div>

                                <div className="card-back">
                                    <img src={FabGf} alt={'FabGf'}/>
                                </div>
                            </div>
                            <div className={'cart_text_name'}>
                                <h3>Fabrizio Turdo</h3>
                                <a href="https://www.linkedin.com/in/fabrizio-turdo-9a0b6b4/">
                                    <img src={lin} alt={'lin'}/>
                                </a>
                                <p>Founder & CEO</p>
                            </div>



                        </div>


                        <div>
                            <div className="team-card">
                                <div className="card-front">
                                    <img src={Fran} alt={'Fran'}/>
                                </div>

                                <div className="card-back">
                                    <img src={FranGf} alt={'FranGf'}/>
                                </div>
                            </div>


                            <div className={'cart_text_name'}>
                                <h3>Francesco Antonaci</h3>
                                <a href="https://www.linkedin.com/in/francesco-antonaci-03b04a35/">
                                <img src={lin} alt={'lin'}/>
                                </a>
                                <p>Co-Founder & COO</p>

                            </div>
                        </div>


                        <div>
                            <div className="team-card">
                                <div className="card-front">
                                    <img src={Dam} alt={'Dam'}/>
                                </div>

                                <div className="card-back">
                                    <img src={DamGf} alt={'DamGf'}/>
                                </div>
                            </div>


                            <div className={'cart_text_name'}>
                                <h3>Damir Matrljan</h3>
                                <a href="https://www.linkedin.com/in/damirmatrljan/">
                                <img src={lin} alt={'lin'}/>
                                </a>
                                <p>Co-Founder & CTO</p>

                            </div>
                        </div>


                        <div>
                            <div className="team-card">
                                <div className="card-front">
                                    <img src={Car} alt={'Car'}/>
                                </div>

                                <div className="card-back">
                                    <img src={CarGf} alt={'CarGf'}/>
                                </div>
                            </div>


                            <div className={'cart_text_name'}>
                                <h3>Carlos Lima</h3>
                                <a href="https://www.linkedin.com/in/carlos-lima-9522892b/">

                                <img src={lin} alt={'lin'}/>
                                </a>
                                <p>Head of Business</p>

                            </div>
                        </div>


                        <div>
                            <div className="team-card">
                                <div className="card-front">
                                    <img src={Kris} alt={'Kris'}/>
                                </div>

                                <div className="card-back">
                                    <img src={KrisGf} alt={'KrisGf'}/>
                                </div>
                            </div>

                            <div className={'cart_text_name'}>
                                <h3>Kristina Turdo</h3>
                                <a href="https://www.linkedin.com/in/kristina-turdo/">
                                <img src={lin} alt={'lin'}/>
                                </a>
                                <p>UI/UX Interaction Designer</p>

                            </div>
                        </div>


                        <div>
                            <div  className="team-card">
                                <div className="card-front">
                                    <img src={Stef} alt={'Stef'}/>
                                </div>

                                <div className="card-back">
                                    <img src={StefGf} alt={'StefGf'}/>
                                </div>
                            </div>

                            <div className={'cart_text_name'}>
                                <h3>Stefania Randazzo</h3>
                                <a href="https://www.linkedin.com/in/stefaniarandazzo/">
                                <img src={lin} alt={'lin'}/>
                                </a>
                                <p>Marketing Specialist</p>

                            </div>
                        </div>


                        <div>
                            <div className="team-card">
                                <div className="card-front">
                                    <img src={Sand} alt={'Sand'}/>
                                </div>

                                <div className="card-back">
                                    <img src={SandGf} alt={'SandGf'}/>
                                </div>
                            </div>

                            <div className={'cart_text_name'}>
                                <h3>Sandro Ferrari</h3>
                                <a href="https://www.linkedin.com/search/results/all/?keywords=sandro%20ferrari&origin=RICH_QUERY_SUGGESTION&position=0&searchId=59638af5-3ab2-445e-9c8c-382c82ec464d&sid=1ar">
                                <img src={lin} alt={'lin'}/>
                                </a>
                                <p>CFO</p>

                            </div>
                        </div>


                        <div>
                            <div className="team-card">
                                <div className="card-front">
                                    <img src={Li} alt={'Li'}/>
                                </div>

                                <div className="card-back">
                                    <img src={LiGf} alt={'LiGf'}/>
                                </div>
                            </div>

                            <div className={'cart_text_name'}>
                                <h3>Lee Saydam</h3>
                                <a href="https://www.linkedin.com/in/lee-saydam/">
                                <img src={lin} alt={'lin'}/>
                                </a>
                                <p>Copywriter & Content Manager</p>

                            </div>
                        </div>


                        <div className={'dogs'}>


                            <div>
                                <div className="team-card">
                                    <div className="card-front">
                                        <img src={Alm} alt={'Alm'}/>
                                    </div>

                                    <div className="card-back">
                                        <img src={AlmGf} alt={'AlmGf'}/>
                                    </div>
                                </div>

                                <div className={'cart_text_name'}>
                                    <h3> Alma</h3>
                                    <p style={{marginTop: '0'}}>Toy Durability Analyst & <br/>Security Guard</p>

                                </div>
                            </div>


                            <div>
                                <div className="team-card">
                                    <div className="card-front">
                                        <img src={Rio} alt={'Rio'}/>
                                    </div>

                                    <div className="card-back">
                                        <img src={RioGf} alt={'RioGf'}/>
                                    </div>
                                </div>

                                <div className={'cart_text_name'}>
                                    <h3>Rio</h3>
                                    <p style={{marginTop: '0'}}>Chief Scratchetist &<br/> Head of Naps</p>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id='Values' className={'home_Values'}>

                <div className={'Values_wrapper'}>

                    <div className={'Values_number'}>

                        <div className={'Values_canter'}>
                            <h3>

                                <CountUp start={0} end={20}
                                         duration={3}
                                         enableScrollSpy={true}
                                         scrollSpyDelay={20}
                                         suffix={t('y')}
                                         prefix={'+'}

                                         >

                                {({ countUpRef }) => (
                                    <div>
                                        <span ref={countUpRef} />
                                    </div>
                                )}
                                </CountUp>
                            </h3>
                            <h5>
                                {t('Of Experience')}

                            </h5>

                        </div>
                        <div className={'Values_canter'}>
                            <h3>
                                <CountUp start={0} end={3} duration={3} enableScrollSpy={true}  suffix={t('y')}>
                                {({ countUpRef }) => (
                                    <div>
                                        <span ref={countUpRef} />
                                    </div>
                                )}
                                </CountUp>
                            </h3>
                            <h5>
                                {t('Framework R&D')}

                            </h5>

                        </div>

                        <div className={'Values_canter'}>
                            <h3>
                                <CountUp start={0} end={15} duration={3} enableScrollSpy={true} scrollSpyDelay={20} suffix={t('K')}>
                                {({ countUpRef }) => (
                                    <div>
                                        <span ref={countUpRef} />
                                    </div>
                                )}
                                </CountUp>
                            </h3>
                            <h5>
                                {t('Situational Responses')}

                            </h5>

                        </div>
                        <div className={'Values_canter'}>
                            <h3>
                                <CountUp start={0} end={2} duration={3} enableScrollSpy={true} scrollSpyDelay={20}>
                                {({ countUpRef }) => (
                                    <div>
                                        <span ref={countUpRef} />
                                    </div>
                                )}
                                </CountUp>
                            </h3>
                            <h5>
                                {t('Office Dogs')}

                            </h5>

                        </div>
                        <div className={'Values_canter'}>
                            <h3>
                                <CountUp start={0} end={4} enableScrollSpy={true} scrollSpyDelay={20}>
                                {({ countUpRef }) => (
                                    <div>
                                        <span ref={countUpRef} />
                                    </div>
                                )}
                                </CountUp>
                            </h3>
                            <h5>
                                {t('Coffees p. P. / Day')}

                            </h5>

                        </div>

                    </div>
                </div>

                <div className={'Values_text'}>
                    <h2>


                        {t('Our Values')}

                    </h2>
                    <h6>
                        <ImQuotesLeft style={{color: '#FFFFFF',
                            width: '10px',
                            height: '10px',
                            marginRight:'8px',
                            marginTop: '-30px'}}/>

                        {t('Culture defines the success of a business.')}

                        <ImQuotesRight style={{color: '#FFFFFF',
                            width: '10px',
                            height: '10px',
                            marginLeft:'8px',
                            marginBottom: '-30px'}}/>


                    </h6>
                    <p>
                        {t('Culture defines')}

                    </p>

                </div>


                <div className={'Values_text_boxs'}>
                    <div className={'Values_box'}>

                        <img data-aos="flip-left"
                             data-aos-easing="ease-out-cubic"
                             data-aos-duration="2000" style={{height: '50px', marginBottom: "25px"}} src={starke} alt={'starke'}/>
                        <div>
                            <h4 className={'box_4'}>
                                {t('We foster strong')}

                            </h4>
                        </div>

                        <p>
                            {t('We believe in relationships')}

                        </p>
                    </div>
                    <div className={'Values_box'}>
                        <img data-aos="flip-left"
                             data-aos-easing="ease-out-cubic"
                             data-aos-duration="2000" style={{height: '50px', marginBottom: "25px"}} src={zeigen} alt={'zeigen'}/>
                        <div className={'box_4'}>
                            <h4>
                                {t('We hold ourselve')}


                            </h4>
                        </div>

                        <p>
                            {t('We take responsibility')}

                        </p>
                    </div>
                    <div className={'Values_box'}>
                        <img data-aos="flip-left"
                             data-aos-easing="ease-out-cubic"
                             data-aos-duration="2000" style={{height: '50px', marginBottom: "25px"}} src={etwas} alt={'etwas'}/>
                        <div className={'box_4'}>
                            <h4>

                                {t('We focus on impact.')}


                            </h4>
                        </div>

                        <p>
                            {t('We focus on making')}

                        </p>
                    </div>
                    <div className={'Values_box'}>

                        <img data-aos="flip-left"
                             data-aos-easing="ease-out-cubic"
                             data-aos-duration="2000" style={{height: '50px', marginBottom: "25px"}} src={kreativ} alt={'kreativ'}/>
                        <div className={'box_4'}>
                            <h4>
                                {t('We are creative & passionate.')}


                            </h4>
                        </div>

                        <p>
                            {t('We are passionate about')}

                        </p>
                    </div>


                </div>

            </section>



           <section id='Milestones' className={'home_Milestones'}>
                <div className={'Milestones_wrapper'}>

                    <div data-aos="flip-up" className={'Milestones_text'}>

                        <h2 data-aos="flip-up">
                            {t('Milestones')}

                        </h2>
                        <hr/>
                        <p>
                            {t('Here’s how FLOWLE came to life…')}

                        </p>
                    </div>

                    <div className={'Milestones_boxs'}>
                        <div className={'Milestones_img'}>

                            <img src={Milestones} alt={'Milestones'}/>
                            <div className={'Milest_1_0'}>
                                <img  src={Milest_2} alt={'Milest_1'}/>
                            </div>
                            <div className={'Milest_1_1'}>
                                <img src={Milest_1} alt={'Milest_1'}/>
                            </div>
                            <div className={'Milest_1_2'}>

                                <img src={Milest_1} alt={'Milest_1'}/>
                            </div>

                        </div>
                        <div className={'Milestones_box'}>
                            <div data-aos="zoom-out-right" className={'box_description'}>
                                <h4>
                                    12/2021
                                </h4>
                                <h3>

                                    {t('FLOWLE | Culture DataS')}

                                </h3>

                            </div>

                            <div data-aos="zoom-out-left" className={'box_description'}>
                                <h4>
                                    11/2020
                                </h4>
                                <h3>
                                    {t('FLOWLE | Culture Data – Proof of value')}

                                </h3>
                            </div>

                            <div data-aos="zoom-out-right" style={{marginTop: '-20px'}} className={'box_description'}>

                                <h4>
                                    10/2020
                                </h4>
                                <h3>
                                    {t('FLOWLE | Culture Data – First MVP')}
                                    <br/>
                                    {t('First MVP')}


                                </h3>
                            </div>
                        </div>

                    </div>
                </div>
            </section>

        </div>
    )
}
export default AboutAs;