import React from 'react';
import {Link} from 'react-router-dom';
import CookieConsent from "react-cookie-consent";
import {useTranslation} from "react-i18next";
import './Cookie.scss'

const Cookie = () => {

    const {t} = useTranslation()


    return (
        <div>
            <CookieConsent

                location="bottom"
                buttonText="OK"

                style={{
                    padding: '15px',
                    fontStyle: 'normal',
                    fontWeight: '300',
                    fontSize: '16px',
                    lineHeight: '148.69%',
                    letterSpacing: '0.07em',
                    alignItems: 'center',
                    justifyContent: 'and',
                    background: '#212529',
                    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                    borderRadius: '8px',
                }}
                buttonStyle={{
                    background: "#009FBF",
                    color: "white",
                    width: '80px',
                    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                    borderRadius: '15px',
                }}
                cookieName="myAwesomeCookieName2"
                expires={150}
            >
                <p>
                    {t('Cookie settings')}
                </p>
                <p>
                    {t('Wir verwenden')}

                    <Link to={'/policies'}>
                        <span>{' '}<u>{t('Cookie-Richtline.')}</u>
                        </span>
                    </Link>


                    {t('and our')}

                    <Link to={'/policies'}>
                        <span>{' '}<u>{t('Cookie Policyk')}</u></span>
                    </Link>


                       
                   

                </p>

            </CookieConsent>


        </div>
    );
};

export default Cookie;