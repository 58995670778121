import React, {Suspense} from 'react';
import ReactDOM from 'react-dom';
import {HashRouter} from "react-router-dom";
import {CookiesProvider} from "react-cookie";
import i18n from "i18next";
import {initReactI18next} from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpApi from 'i18next-http-backend';
import './index.scss';
import App from './App';
import 'bootstrap/dist/js/bootstrap.js';
import { MutatingDots} from "react-loader-spinner";







i18n
    .use(initReactI18next)
    .use(LanguageDetector)
    .use(HttpApi)
    .init({
        supportedLngs: ['en', 'de'],
        fallbackLng: "en",
        detection: {
            order: ['path', 'cookie', 'htmlTag', 'localStorage',
                'subdomain'],
            caches: ['cookie'],
        },
        backend: {
            loadPath: '/asset/locales/{{lng}}/translation.json',
        }

    });


const loadingMarkup = (
<div className={'Mutati'}>
    <MutatingDots
        height="100"
        width="100"
        color="#009fbf"
        secondaryColor= '#009fbf'
        radius='12.5'
        ariaLabel="mutating-dots-loading"
        visible={true}/>
</div>



)


ReactDOM.render(
    <Suspense fallback={loadingMarkup }>


                  <CookiesProvider>
                      <HashRouter>
                          <App/>
                      </HashRouter>
                  </CookiesProvider>



    </Suspense>,

    document.getElementById('root')
);

