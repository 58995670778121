import React, {useEffect, useRef} from 'react';
import './login.scss'
import '../../pages/Demo/ReguestDemo.scss'
import {Link} from "react-router-dom";
import Flo from "../../components/footer/img.png";
import {AiOutlineLeft} from "react-icons/ai";
import {useTranslation} from "react-i18next";
import emailjs from "@emailjs/browser";
import {useNavigate} from "react-router";
import {useForm} from "react-hook-form";


const Login = () => {

    const nav = useNavigate();


    const form = useRef();

    function sendEmail(data, e) {
        e.preventDefault();
        emailjs.sendForm('service_6r21bzq', 'template_y4mbqsx', e.target, 'user_7fdrdAgJSGYSdvUh1dWK5')
            .then((result) => {
                console.log(result.text);
            }, (error) => {
                console.log(error.text);
            });
        e.target.reset()

        nav("thanksContact");


        reset()
    }


    const {
        register,
        handleSubmit,
        formState: {errors},
        reset
    } = useForm({
        mode: "onBlur",
        defaultValues: {
            email: "",
            checkbox: "",
            password: ""

        }
    });

    function ScrollToTopOnMount() {
        useEffect(() => {
            window.scrollTo(0, 0);
        }, []);

        return null;
    }

    const {t} = useTranslation()
    return (

        <div className={'contact_wrapper_login'}>
            <div className={'login_back'}>
                <ScrollToTopOnMount/>
                <Link to="/">
                    <div className={'back'}>
                        <p><AiOutlineLeft style={{color: 'white', marginRight: "10px"}}/>
                            {t('Back to: Flowle.com')}
                        </p>
                    </div>
                </Link>

            </div>
            <div className={'wrapper_forms'} style={{height: '100vh'}}>


                <div className={'contact_form'}>

                    <h2>
                        Welcome to FLOWLE!
                    </h2>
                    <form className={'contact_colum'} ref={form} onSubmit={handleSubmit(sendEmail)}>


                        <div className="row">


                            <input type="text"
                                   placeholder={t('E-Mail')}
                                   {...register("email", {
                                       required: 'This is required',
                                       pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                                   })}
                            />
                            {errors?.email?.type === "required" &&
                            <p className={'errors'}>{t('This field is required')}</p>}
                            {errors?.email?.type === "pattern" && (
                                <p className={'errors'}>{t('Enter a valid email address')}</p>)}


                            <input type="text"
                                   placeholder={t('Password')}
                                   {...register("Password", {
                                       required: 'This is required'
                                   })}
                            />
                            {errors?.email?.type === "required" &&
                            <p className={'errors'}>{t('This field is required')}</p>}
                        </div>


                        <div className="formname">

                            <input id="checkbox"
                                   type="checkbox"
                                   {...register("checkbox", {
                                       required: true,
                                   })}
                            />
                            {`errors`?.checkbox?.type === "required" &&
                            <p className={'errors'}>{t('This field is required')}</p>}

                            <label htmlFor={"checkbox"}>
                                <p style={{
                                    marginTop: '3px',
                                    fontStyle: 'normal',
                                    fontWeight: '400',
                                    fontSize: '12px'
                                }}>
                                    {t('Remember me')}

                                </p>


                            </label>
                        </div>


                        <button style={{marginRight: '7px', marginLeft: '0'}}
                                className={'sing_in'}>

                            <span style={{
                                border: '1px solid transparent',
                                borderRadius: '6px'
                            }} className={'Button-inner'}>
                                  <div style={{marginLeft: '120px'}}>
                                      {t('Login')}
                                     </div>
                            </span>

                        </button>

                    </form>


                </div>


            </div>
            <div className={'logo_address'}>


                <img src={Flo} alt={'flo'}/>


                <p>© 2022 by GROWITHY AG

                    <Link to={'/policies'}>


                        <u> Privacy& Cookie Policy</u>


                    </Link>
                </p>


            </div>

        </div>
    );
};

export default Login;