import React from 'react';
import './fast.scss'



const Fast = () => {
    return (

      <div>
            <div className="slider">
                <div className="slide-track">

                </div>
            </div>
        </div>
    );
};

export default Fast;
