export const MenuAboutAs = [

    {
        title: 'What We Stand For',
        href: 'aboutUs/#Mission',
        cName: 'linc_hover'
    },
    {
        title: 'The FLOWLE Story',
        href: 'aboutUs/#Story',
        cName: 'linc_hover'
    },
    {
        title: 'Meet The Team',
        href: 'aboutUs/#Meet',
        cName: 'linc_hover'
    },
    {
        title: 'Our Values',
        href:'aboutUs/#Values',
        cName: 'linc_hover'
    },
    {
        title: 'Milestones',
        href: 'aboutUs/#Milestones',
        cName: 'linc_hover'
    }
]