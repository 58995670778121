import React, {useEffect} from 'react';
import './Policy.scss'
import {useTranslation} from "react-i18next";


const Policy = () => {
    function ScrollToTopOnMount() {
        useEffect(() => {
            window.scrollTo(0, 0);
        }, []);

        return null;
    }

    const {t} = useTranslation()
    return (
        <div className={'policy_wrapper'}>

            <ScrollToTopOnMount/>
            <div className={'policy'}>
                <div>

                    <h1 style={{marginBottom: '30px'}}>
                        {t('Privacy Policy')}</h1>

                    <p>
                        {t('This website www.flowle.com')}
                        <span> {t('“Site”')}</span>
                        {t('is owned and operated by Growithy AG, with its registered address at Tödistrasse 16, 8002 - Zurich) (hereinafter,')}
                        <span>
                          {t('“Growithy“, “we”, “our”,')}

                      </span>
                        {t('and')}

                        <span>
                            {t('“us”).')}
                            </span>

                    </p>
                    <p>
                        {t('At Growithy, we are committed')}

                    </p>
                    <p>
                        {t('This Privacy Policy explains')}

                    </p>
                    <p>
                        {t('We also process personal')}

                    </p>
                    <p>
                        {t('The Site is not intended for')}

                    </p>

                    <p style={{marginBottom: '50px'}}>
                        {t('We are not required to appoint')}

                    </p>

                </div>


                <div>
                    <h2>
                        {t('CONTACTING US')}
                    </h2>

                </div>

                <p>
                    {t('If you have any questions about')}

                </p>


                <p><span>{t('by post:')}</span> {t('Growithy AG, with')}</p>
                <p style={{marginBottom: '50px'}}>
                    <span>{t('by email:')}</span>{t('contact@growithy.com')}</p>


                <div>
                    <h2 style={{marginBottom: '20px'}}>
                        {t('DATA PROTECTION PRINCIPLES')}

                    </h2>

                    <p>
                        {t("Growithy adheres to the following")}

                    </p>


                    <p>
                            <span>
                                {t('Lawfulness, fairness and transparency')}

                            </span>
                        {t('data must be processed lawfully')}
                    </p>
                    <p>
                            <span>
                             {t('Purpose limitation')}

                        </span>
                        {t('data must be collected for specified')}
                    </p>
                    <p>
                            <span>
                            {t('Data minimization')}
                            </span>
                        {t('data must be adequate')}
                    </p>
                    <p>
                            <span>
                            {t('Accuracy')}

                            </span>
                        {t('data must be')}
                    </p>
                    <p>
                            <span>
                            {t('Storage limitation')}
                            </span>
                        {t('data must be kept in')}
                    </p>
                    <p style={{marginBottom: '70px'}}>
                            <span>
                            {t('Integrity and')}

                             </span>
                        {t('data must be processed')}
                    </p>

                </div>

                <div>
                    <h2 style={{marginBottom: '50px'}}>

                        {t('PERSONAL INFORMATION WE COLLECT')}

                    </h2>
                    <p style={{
                        fontStyle: 'italic',
                        fontWeight: '300',
                        fontSize: '14px',
                        lineHeight: '146.69%'
                    }}>
                        {t('Information you give us')}

                    </p>
                    <p>
                        {t('You may provide us with information')}
                    </p>


                    <p>
                        {t('request additional information')}
                    </p>
                    <p>
                        {t('try the demo version of our service')}
                    </p>
                    <p>
                        {t('log in to access and use the service')}
                    </p>


                    <p>
                        {t('The categories of personal')}

                    </p>

                    <p>
                        {t('first and last name')}
                    </p>
                    <p>
                        {t('job title and company name')}
                    </p>
                    <p>
                        {t('email address')}
                    </p>
                    <p>
                        {t('phone number')}
                    </p>
                    <p>
                        {t('account details')}
                    </p>
                    <p>
                        {t('any other identifier')}
                    </p>

                    <p style={{marginBottom: '50px'}}>
                        {t('We do not generally seek')}

                    </p>
                    <p style={{
                        fontStyle: 'italic',
                        fontWeight: '300',
                        fontSize: '14px',
                        lineHeight: '146.69%'
                    }}>
                        {t('Information you give us')}


                    </p>
                    <p style={{marginBottom: '70px'}}>
                        {t('Growithy collects, stores')}

                    </p>
                </div>


                <div>
                    <h2>
                        {t('GROWITHY AS DATA PROCESSOR')}
                    </h2>


                    <p>
                        {t('We collect and process personal')}
                    </p>

                    <p>
                        {t('The customers of Growithy are')}

                    </p>

                    <p>
                        {t('As a data processor, Growithy')}

                    </p>

                    <p>
                        {t('Where Growithy is acting as a')}


                    </p>

                    <p style={{marginBottom: '70px'}}>
                        {t('Growithy will retain personal')}
                    </p>
                </div>


                <div>
                    <h2>
                        {t('WHAT WE DO WITH YOUR PERSONAL DATA')}

                    </h2>
                    <p style={{marginBottom: '80px'}}>
                        {t('As a data controller, Growithy will')}
                    </p>
                </div>

                <div>

                </div>

            </div>

            <div className={'Purposes_for'}>
                <div className={'Purposes_left'}>


                    <div className={'Purposes_left_1'}>
                        <p style={{
                            fontStyle: 'normal',
                            fontWeight: '400',
                            fontSize: '16px',
                            lineHeight: '160%',
                        }}>
                            {t('Purposes for which we will process the information')}

                        </p>
                    </div>
                    <div className={'Purposes_rate_1'}>
                        <p style={{
                            fontStyle: 'normal',
                            fontWeight: '400',
                            fontSize: '16px',
                            lineHeight: '160%',
                        }}>

                            {t("Legal Basis for the processing")}
                        </p>
                    </div>


                    <div className={'Purposes_left_1'}>
                        <p>
                            {t('To provide you with')} </p>
                    </div>
                    <div className={'Purposes_rate_1'}>

                        <p>
                            {t('It is in our legitimate interests to')}
                        </p>
                    </div>


                    <div className={'Purposes_left_1'}>
                        <p>
                            {t('To update you on service and benefits we offer.')}
                        </p>
                    </div>
                    <div className={'Purposes_rate_1'}>
                        <p>
                            {t('It is in our legitimate interests to market our service.')}
                        </p>
                    </div>


                    <div className={'Purposes_left_1'}>
                        <p>
                            {t('To personalize our service and the Site to you.')}
                        </p>
                    </div>
                    <div className={'Purposes_rate_1'}>
                        <p>
                            {t('It is in our legitimate interests to enhance your experience')}
                        </p>
                    </div>


                    <div className={'Purposes_left_1'}>
                        <p>
                            {t('To send you information regarding changes')}

                        </p>
                    </div>
                    <div className={'Purposes_rate_1'}>
                        <p>
                            {t('It is in our legitimate interests to ensure that')}
                        </p>
                    </div>

                    <div className={'Purposes_left_1'}>
                        <p>
                            {t('To administer our Site including')}
                        </p>
                        <p>
                            {t('To improve our Site to ensure that consent')}

                        </p>
                        <p>
                            {t('To keep our Site safe and secure.')}

                        </p>

                    </div>
                    <div className={'Purposes_rate_1'}>
                        <p>
                            {t('For all these categories, it is in our')}
                        </p>
                    </div>
                </div>

            </div>


            <div className={'policy'}>
                <p style={{marginBottom: '100px', marginTop: '50px'}}>

                    {t('Growithy will only use your personal')}

                </p>


                <h2 style={{marginBottom: '30px'}}>
                    {t('DISCLOSURE OF YOUR PERSONAL DATA TO THIRD PARTIES')}

                </h2>

                <p style={{marginBottom: '20px'}}>

                    {t('Growithy will not sell,')}

                </p>
                <p style={{marginBottom: '20px'}}>
                    {t('Personal information will be shared')}

                </p>

                <p style={{marginBottom: '20px'}}>
                    {t('Personal information will be shared also')}

                </p>

                <p style={{marginBottom: '20px'}}>
                    {t('In addition, we may disclose information about you:')}

                </p>

                <p style={{marginBottom: '10px'}}>
                    {t('if we are under a duty to disclose or share')}


                </p>
                <p>
                    {t('if necessary to protect')}

                </p>
                <p style={{marginBottom: '100px'}}>
                    {t('to enforce or apply our terms and')}

                </p>

                <h2 style={{marginBottom: '30px'}}>
                    {t('STORING OF YOUR PERSONAL INFORMATION')}
                </h2>

                <p style={{marginBottom: '30px'}}>
                    {t('Your personal information will not')}

                </p>

                <p style={{marginBottom: '70px'}}>
                    {t('In the event this is necessary to provide')}

                </p>

                <h2 style={{marginBottom: '30px'}}>
                    {t('SECURITY OF YOUR PERSONAL DATA')}

                </h2>
                <p style={{marginBottom: '20px'}}>
                    {t('Growithy use appropriate technical')}

                </p>
                <p style={{marginBottom: '20px'}}>
                    {t('We use industry-standard')}
                </p>

                <p style={{marginBottom: '20px'}}>
                    {t('Only authorized personnel and third')}

                </p>

                <p style={{marginBottom: '100px'}}>
                    {t('Despite these precautions, however,')}


                </p>
                <h2 style={{marginBottom: '30px'}}>
                    {t('HOW LONG WE KEEP YOUR PERSONAL DATA')}
                </h2>

                <p style={{marginBottom: '20px'}}>
                    {t('Your personal information will')}

                </p>
                <p style={{marginBottom: '70px'}}>
                    {t('Growithy will retain your personal data')}


                </p>

                <h2 style={{marginBottom: '70px'}}>

                    {t('YOUR RIGHTS')}

                </h2>

                <h2 style={{marginBottom: '30px'}}>
                    {t('ACCESS TO AND UPDATING')}

                </h2>

                <p style={{marginBottom: '30px'}}>
                    {t('You have the right to access')}

                </p>
                <p style={{marginBottom: '30px'}}>

                    {t('You may also have the right to receive personal')}

                </p>

                <p style={{marginBottom: '100px'}}>
                    {t('We want to make sure that')}

                </p>

                <h2 style={{marginBottom: '50px'}}>
                    {t('RIGHT TO OBJECT')}

                </h2>

                <p style={{
                    fontStyle: 'italic',
                    fontWeight: '300',
                    fontSize: '14px',
                    lineHeight: '146.69%'
                }}>
                    {t('Direct marketing')}

                </p>

                <p style={{marginBottom: '50px'}}>
                    {t('You have the right to object at any time')}

                </p>
                <p style={{
                    fontStyle: 'italic',
                    fontWeight: '300',
                    fontSize: '14px',
                    lineHeight: '146.69%'
                }}>
                    {t('Where we process your information')}

                </p>
                <p style={{marginBottom: '70px'}}>
                    {t('You also have the right to object')}

                </p>

                <h2 style={{marginBottom: '30px'}}>
                    {t('OTHER RIGHTS')}

                </h2>

                <p style={{marginBottom: '20px'}}>
                    {t('You also have the following')}

                </p>

                <p style={{marginBottom: '20px'}}>
                    {t('In certain circumstances,')}

                </p>
                <p>
                    {t('request the erasure')}

                </p>
                <p style={{marginBottom: '20px'}}>
                    {t('restrict the processing of')}

                </p>


                <p style={{marginBottom: '20px'}}>
                    {t('Please note that the above')}

                </p>

                <p style={{marginBottom: '100px'}}>
                    {t('For example, we may')}

                </p>

                <h2 style={{marginBottom: '30px'}}>
                    {t('EXERCISING YOUR RIGHTS')}

                </h2>

                <p style={{marginBottom: '40px'}}>
                    {t('You can exercise any of your')}

                </p>
                <p style={{marginBottom: '20px'}}>
                    {t('Save as described in this Privacy')}

                </p>
                <p style={{marginBottom: '100px'}}>
                    {t('Where we have reasonable doubts')}

                </p>

                <h2 style={{marginBottom: '30px'}}>
                    {t('LINKS')}

                </h2>
                <p style={{marginBottom: '100px'}}>
                    {t('The Site may contain links to')}

                </p>

                <h2 style={{marginBottom: '30px'}}>
                    {t('COMPLAINTS')}

                </h2>
                <p style={{marginBottom: '20px'}}>
                    {t('If you have any questions')}

                </p>
                <p style={{marginBottom: '100px'}}>
                    {t('If you are in located in the EEA')}

                </p>

                <h2 style={{marginBottom: '50px'}}>
                    {t('CHANGES TO OUR PRIVACY POLICY')}

                </h2>
                <p style={{marginBottom: '50px'}}>
                    {t('Growithy reserves the right')}

                </p>

                <p style={{marginBottom: '200px'}}>
                    {t('Updated and Effective as of 27.10.2021')}

                </p>


                <h1 style={{marginBottom: '50px'}}>
                    {t('Cookie Policy')}

                </h1>

                <p style={{marginBottom: '20px'}}>
                    {t('In order to improve the Site')}

                </p>

                <p style={{marginBottom: '20px'}}>
                    {t('If you don’t want us to use cookies when')}

                </p>

                <p style={{marginBottom: '50px'}}>
                    {t('You can find more information about')}
                    <a href={'https://allaboutcookies.org/'}><u>{t('www.allaboutcookies.org.')}</u></a>{t('This website will also')}
                </p>


                <h2 style={{marginBottom: '30px'}}>
                    {t('WE USE THE FOLLOWING CATEGORIES OF COOKIES')}

                </h2>
                <p style={{
                    fontStyle: 'italic',
                    fontWeight: '300',
                    fontSize: '14px',
                    lineHeight: '146.69%'
                }}>
                    {t('"Necessary Cookies" (so-called Technical Cookies)')}

                </p>

                <p>
                    {t('They are necessary to allow you')}

                </p>
                <p style={{
                    fontStyle: 'italic',
                    fontWeight: '300',
                    fontSize: '14px',
                    lineHeight: '146.69%'
                }}
                >
                    {t('"Session cookies"')}

                </p>
                <p style={{marginBottom: '50px'}}>
                    {t('These are cookies that are active')}

                </p>


                <h2 style={{marginBottom: '50px'}}>
                    {t('“Cookies from third parties”')}

                </h2>


                <p style={{marginBottom: '50px'}}>
                    {t('We exclusively use “Google Analytics”')}

                </p>
                <p>
                    {t('The Site uses Google Analytics,')}

                </p>
            </div>


        </div>
    );
};

export default Policy;