import React from 'react';
import NavLinks from "./NavLinks";






const DecstTopHeader = () => {

    return (

        <div className={'display_desktop'}>

              <NavLinks  />




            </div>


    );
};

export default DecstTopHeader;