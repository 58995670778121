import React, {useEffect, useRef} from 'react';
import emailjs from "@emailjs/browser";
import {useForm} from "react-hook-form";
import {Link} from "react-router-dom";
import './ReguestDemo.scss'
import {AiOutlineLeft} from "react-icons/ai";
import {useNavigate} from "react-router";
import {useTranslation} from "react-i18next";
import Flo from "../../components/footer/img.png";


const ReguestDemo = () => {
    const {t} = useTranslation()

    const nav = useNavigate();

    function ScrollToTopOnMount() {
        useEffect(() => {
            window.scrollTo(0, 0);
        }, []);

        return null;
    }




    const form = useRef();

    function sendEmail(data, e) {
        e.preventDefault();
        emailjs.sendForm('service_2vkaat9', 'template_klgawjn', e.target, 'user_7fdrdAgJSGYSdvUh1dWK5')
            .then((result) => {
                console.log(result.text);
            }, (error) => {
                console.log(error.text);
            });
        e.target.reset()

        nav("thanksReaching");
        reset()
    }


    const {
        register,
        handleSubmit,
        formState: {errors},
        reset
    } = useForm({
        mode: "onBlur",
        defaultValues: {
            Title:'',
            FirstName : "",
            LastName: "",
            email: "",
            PhoneNo:"",
            CompanyNam:"",
            CompanySize:"",
            WhereDidYouHearAboutUs:'',
            message: "",
            checkbox:"",






        }
    });



      return (


        <div className={'contact_wrapper_login'}>
            <div className={'login_back'} style={{}}>
                <Link to="/">
                    <div className={'back'}>
                         <p> <AiOutlineLeft style={{color:'white',marginRight:"10px"}}/>

                             {t('Back to: Flowle.com')}

                         </p>
                    </div>
                </Link>

            </div>
            <ScrollToTopOnMount />
            <div className={'wrapper_forms'} style={{marginTop: '100px'}}>
            <div className={'contact_form'}>
                <div className={'form-tattle'}>
                    <h2>
                        {t('Request Demo')}

                    </h2>
                    <p>
                        {t('We would love to show ')}

                    </p>
                    <p style={{fontWeight: '500',
                        fontSize: '14px',
                        lineHeight: '140%'}}>
                        {t('Fill out the form and we will get back to you shortly.')}
                    </p>
                </div>

                <form className={'contact_colum'} ref={form} onSubmit={handleSubmit(sendEmail)} >

                    <div className="row">
                        <select style={{marginTop: '20px'}}
                                {...register("Title", {
                                    required: true,
                                })}>

                            <option value="">{t('Title')}</option>
                            <option value="Mr.">{t('Mr.')}</option>
                            <option value="Ms.">{t('Ms.')}</option>
                            <option value="Mx.">{t('Mx.')}</option>
                            <option value="N.A.">{t('N.A.')}</option>

                        </select>
                        {errors?.WhereDidYouHearAboutUs?.type === "required" && <p className={'errors'}>Please choose title.</p>}
                        <input type="text"
                               placeholder={t('First Name *')}
                               {...register("FirstName", {
                                   required: true,

                                   pattern: /^[A-Za-z]+$/i,
                               })}
                        />

                        {errors?.FirstName?.type === "required" && <p className={'errors'}>{t('This field is required')}</p>}
                        {errors?.FirstName?.type === "pattern" && (<p className={'errors'}>{t('Alphabetical characters only')}</p>)}


                        <input type="text"
                               placeholder={t('Last Name *')}
                               {...register("LastName", {
                                   required: true,
                                   pattern: /^[A-Za-z]+$/i,
                               })}
                        />

                        {errors?.LastName?.type === "required" && <p className={'errors'}>{t('This field is required')}</p>}

                        {errors?.LastName?.type === "pattern" && (<p className={'errors'}>{t('Alphabetical characters only')}</p>)}


                        <input type="text"
                               placeholder={t('Business E-Mail *')}
                               {...register("email", {
                                   required: 'This is required',
                                   pattern:/^[\w]{1}[\w-\.]*@[\w-]+\.[com]{2,4}$/i
                               })}
                        />
                        {errors?.email?.type === "required" && <p className={'errors'}>{t('This field is required')}</p>}
                        {errors?.email?.type === "pattern" && (<p className={'errors'}>{t('Enter a valid email address')}</p>)}



                            <input type="tel"
                                   placeholder={t('Phone No.')}
                                   {...register("PhoneNo", {
                                       required: true,
                                       pattern: /^[\d\+][\d\(\)\ -]{4,14}\d$/


                                   })}
                            />

                            {errors?.PhoneNo?.type === "required" && <p className={'errors'}>{t('This field is required')}</p>}
                            {errors?.PhoneNo?.type === "pattern" && (<p className={'errors'}>{t('Only number')}</p>)}



                            <input type="text"
                                   placeholder={t('Company Name')}
                                   {...register("CompanyNam", {
                                       required: true,})}
                            />

                            {errors?.CompanyNam?.type === "required" && <p className={'errors'}>{t('This field is required')}</p>}







                            <select style={{marginTop: '20px'}}
                                    {...register("CompanySize", {
                                        required: true,
                                    })}>

                                <option value="">{t('Company Size')}</option>
                                <option value="0-20 employees">{t('0-20 employees')}</option>
                                <option value="21-50 employees">{t('21-50 employees')} </option>
                                <option value="51-250 employees">{t('51-250 employees')} </option>
                                <option value="> 250 employees">>{t('250 employees')} </option>

                            </select>
                        {errors?.CompanySize?.type === "required" && <p className={'errors'}>Please choose service.</p>}




                            <select style={{marginTop: '20px'}}
                                    {...register("WhereDidYouHearAboutUs", {
                                        required: true,
                                    })}>

                                <option value="">{t('Where did you hear about us?')}</option>
                                <option value="Personal recommendation">{t('Personal recommendation')}</option>
                                <option value="LinkedIn">{t('LinkedIn')} </option>
                                <option value="Social media">{t('Social media')}</option>
                                <option value="Search engine">{t('Search engine')} </option>
                                <option value="Advertisement">{t('Advertisement')} </option>
                                <option value="E-Mail or newsletter">{t('E-Mail or newsletter')} </option>
                                <option value="Article">{t('Article')} </option>
                                <option value="Other">{t('Other')}</option>

                            </select>


                            {errors?.WhereDidYouHearAboutUs?.type === "required" && <p className={'errors'}>Please choose service.</p>}




                    </div>


                    <textarea className="form-control"
                              placeholder={t('Message')}
                              style={{height: 150, margin: 0}}
                              {...register("message", {
                                  required: true,

                              })}/>

                    {errors?.message?.type === "required" && <p style={{fontWeight: '30',
                        fontSize: '20px',
                        lineHeight: '134.69%',
                        letterSpacing: '0.03em',
                        color:'red'}}>

                        {t('Please write a short message...')}

                    </p>}


                    <div className="formname">

                        <input id="checkbox"
                               type="checkbox"
                               {...register("checkbox", {
                                   required: true,})}
                        />
                        {`errors`?.checkbox?.type === "required" && <p className={'errors'}>

                            {t('This field is required')}

                        </p>}

                        <label htmlFor={"checkbox"}  style={{ marginTop: '20px'}}>
                            <p style={{fontWeight: '300',
                                fontSize: '10px',
                                lineHeight: '134.69%',
                                letterSpacing: '0.03em',
                                textAlign: 'start'
                            }}>
                                {t('I have read the')}

                                {' '}
                                <Link to={'/policies'}>
                                    <u>{t('Privacy Policy')}</u>
                                </Link>

                                {t('and agree to the collection')}
                            </p>


                        </label>
                    </div>


                    <button style={{marginRight: '7px', marginLeft: '0', marginTop: '20px'}}
                            className={'sing_in'} >

                            <span style={{border: '1px solid transparent',
                                borderRadius: '6px'}} className={'Button-inner'}>
                                  <div style={{marginLeft: '90px'}}>
                                      {t('REQUEST DEMO')}


                                  </div>
                            </span>

                    </button>

                </form>


            </div>
            </div>
            <div className={'logo_address'} style={{marginTop: '85px'}}>


                <img src={Flo} alt={'flo'}/>


                <p>© 2022 by GROWITHY AG

                    <Link to={'/policies'}>


                        <u> Privacy& Cookie Policy</u>


                    </Link>
                </p>


            </div>
        </div>
    );
};

export default ReguestDemo;