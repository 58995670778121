import React, {useEffect} from 'react';
import ReactPlayer from 'react-player'
import './Home.scss'
import ControlledCarousel from "../../components/carousel/Carousel";
import Smart from './homeImage/Smart_data_icon.png'
import Feature from './homeImage/img_7.png'
import Grafik from './homeImage/img_2.png'
import Video from './homeImage/img_9.png'
import Viel from './homeImage/Vielseitige_Anwendbarkeit_icon.png'
import Einzi from './homeImage/img_11.png'
import Fast from "../../components/faststik/Fast";
import AOS from 'aos';
import "aos/dist/aos.css";
import {Link} from "react-router-dom";
import {AiFillCaretRight} from "react-icons/ai";
import Statege from './homeImage/img_6.png'
import SwipeToSlide from "../../components/swiperSlader/swiperSlader";
import {useTranslation} from "react-i18next";
import {ImQuotesRight} from "@react-icons/all-files/im/ImQuotesRight";
import {ImQuotesLeft} from "@react-icons/all-files/im/ImQuotesLeft";
import {Helmet} from "react-helmet";




const Home = () => {


    function ScrollToTopOnMount() {
        useEffect(() => {
            window.scrollTo(0, 0);
        }, []);

        return null;
    }


    useEffect(() => {
        AOS.init({
            duration: 2000,
            disable: window.innerWidth < 1300,
        });
        AOS.refresh();
    }, []);

    const {t} = useTranslation()

    return (
        <div className={'home_wrapper'}>

            <Helmet>
                <meta charSet="utf-8" />
                <title>FLOWLE | Culture Data</title>

            </Helmet>

            <ScrollToTopOnMount/>
            <section id={'home_banner'} className={'home_banner'}>

                <ControlledCarousel/>

            </section>

            <section id={'home2'} className={'home_bottom'}>

                <div className={'technology_wrapper'}>


                    <div className={'technology_intro_section'}>
                        <div className={'technology_tattle_intro'}>
                            <h2 data-aos="fade-right">{t('The FLOWLE - Technology')}</h2>
                            <hr/>
                            <p data-aos="fade-left">
                                {t('We specialize')}

                            </p>
                        </div>

                        <div className={'technology_tattle_intro_p'}>
                            <img data-aos="flip-left"
                                 data-aos-easing="ease-out-cubic"
                                 data-aos-duration="4000"
                                 src={Grafik} alt={'grafik'}/>
                        </div>
                    </div>
                    <div>

                    </div>

                    <div className={'technology_icons_features'}>
                        <div className={'features_boxes'}>
                            <div className={'icons_box'}>

                                <div className={'icon_size'}>
                                    <img data-aos="flip-left" className={'icons_img'} src={Feature} alt={'Feature1'}/>
                                </div>


                                <div>
                                    <h3>{t('The Culture')}</h3>
                                </div>
                                <div>
                                    <p>{t('The disruptive technology of FLOWLE')}</p>
                                </div>

                            </div>


                            <div className={'icons_box'}>
                                <div className={'icon_size'}>
                                    <img data-aos="flip-left" className={'icons_img'} src={Smart} alt={'Smart'}/>
                                </div>

                                <div>
                                    <h3>{t('Progress')}Smart Data</h3>
                                </div>
                                <div>
                                    <p>
                                        {t('Data-based statements')}
                                    </p>
                                </div>

                            </div>


                            <div className={'icons_box'}>
                                <div className={'icon_size'}>
                                    <img data-aos="flip-right" className={'icons_img'} src={Viel} alt={'Viel'}/>
                                </div>



                                <h3>
                                    {t('Versatility')}
                                </h3>
                                <div>
                                    <p>
                                        {t('Our KPIs')}
                                    </p>
                                </div>

                            </div>


                            <div className={'icons_box'}>
                                <div className={'icon_size'}>
                                    <img data-aos="flip-right" className={'icons_img'} src={Einzi} alt={'Einzi'}/>
                                </div>


                                <div>
                                    <h3>{t('Unique')}</h3>
                                </div>
                                <div>
                                    <p>
                                        {t('The intuitive')}
                                    </p>
                                </div>

                            </div>
                        </div>


                    </div>

                </div>

            </section>

            <section id={'home3'} className={'home_video'}>


                <div className={'video_tattle'}>
                    <h2>
                        {t('Tauchen Sie')}

                    </h2>
                </div>

              <ReactPlayer
                    className='react-player'
                    playIcon={
                        <button style={{marginLeft: '0', marginBottom: '30px', marginRight: "10px"}}
                                      className={'sing_in'}>
                            <span className={'Button-inner'}>
                            {t('Play video')} <AiFillCaretRight style={{marginLeft: '3px'}} size={15}/>
                            </span>
                    </button>

                    }
                    playing
                    light={Video}
                    url='https://www.youtube.com/watch?v=Q9hMppmV3Y0'
                    width='100vw'
                    height='700px'
                />


                <div className={'technology_ready'}>
                    <h2 data-aos="fade-right">
                        {t('Interested')}

                    </h2>

                    <div>

                                <Link to="product">
                                    <button style={{marginTop: '28.5px'}} className={'sing_in'}>
                            <span className={'Button-inner'}>
                                {t('Learn More')}

                               </span>
                                    </button>
                                </Link>

                    </div>
                </div>


            </section>

            <section id={'home4'} className={'home_bottom_strat'}>

                <div className={'home_strategic'}>
                    <div data-aos="fade-down-right" className={'home_tattle'}>
                        <h2>
                            {t('Your Organizational')}

                            <hr/>
                        </h2>

                        <p>
                            {t('In today’s world')}
<br/>
                            {t('A strong')}

                        </p>
                    </div>
                    <div className={'home_image_tattle'}>
                        <img data-aos="fade-down-left" src={Statege} alt={'srtategis'}/>
                    </div>

                </div>

            </section>

            <section id={'home5'} className={'home_tattle_cover'}>

                <div className={'home_tattle_cov'}>


                    <h3>


                           <ImQuotesLeft style={{color: '#C4C4C4',
                               marginRight:'10px',
                               marginTop: '-30px'}}/>
                            {t('Using insightful')}
                        <ImQuotesRight style={{color: '#C4C4C4',
                        marginLeft:'10px',
                            marginBottom: '-40px'}}/>

                    </h3>

                </div>

            </section>

            <section id={'home6'} className={'organization'}>
                <div className={'organization_wrapper'}>
                    <h2>
                        {t('Is Your')}

                    </h2>



                    <div className={'Contact_button'}>

                                <Link to="contactUs">
                                    <button className={'sing_In'}>
                            <span className={'Button-Inner'}>
                                {t('Contact Us')}

                               </span>
                                    </button>
                                </Link>

                    </div>
                </div>
            </section>


            <section id={'home7'} className={'home_bottom_tu'}>
                <div style={{padding: "0"}} className={'technology_wrapper'}>

                    <Fast/>
                </div>
            </section>


            <section id={'home8'} className={'home_bottom_tu'}>
                <div className={'main_carusel_wrapper'}>

                    <div className={'main_text_carusel'}>
                        <h2 data-aos="fade-right">
                            {t("Why Our")}
                            <hr/>
                        </h2>

                    </div>

                    <SwipeToSlide/>

                </div>

            </section>


        </div>


    );
};


export default Home;