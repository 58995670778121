export const MenuBenefits = [

    {

        title: 'Fields of Application',
        href: 'Benefits/#Fields',
        cName: 'linc_hover',

    },
    {

        title: 'How You Profit',
        href: 'Benefits/#Profit',
        cName: 'linc_hover',

    }

]