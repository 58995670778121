export const MenuWhyFlowle = [

    {

        title: 'Problem Areas',
        href: 'whyFlowle/#Areas',
        cName: 'linc_hover'
    },
    {
        title: 'The FOLOWLE Solution',
        href: 'whyFlowle/#Flowle',
        cName: 'linc_hover'
    },
    {
        title: 'What Sets FLOWLE Apart',
        href: 'whyFlowle/#Solution',
        cName: 'linc_hover'
    }



]