export const MenuProduct = [

    {

        title: 'Smart Data Analytics',
        href: 'product/#Smart',
        cName: 'linc_hover',

    },
    {

        title: 'FLOWLE | Culture Datas',
        href: 'product/#Culture',
        cName: 'linc_hover',

    },
    {
        title: 'FLOWLE | Strategy',
        href: 'product/#Strategy',
        cName: 'linc_hover',

    },
    {
        title: 'FLOWLE | Recruitment',
        href:  'product/#Recruitment',
        cName: 'linc_hover',

    },
    {
        title: 'FLOWLE | Leadership',
        href:  'product/#Leadership',
        cName: 'linc_hover',

    },
    {
        title: 'Process',
        href:  'product/#Process',
        cName: 'linc_hover',

    }
]