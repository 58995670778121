import React from 'react';
import '../login/login.scss';
import {Link} from "react-router-dom";
import {AiOutlineLeft} from "react-icons/ai";
import {useTranslation} from "react-i18next";




const ThanksReaching = () => {

    const {t} = useTranslation()
    return (
        <div className={'contact_wrapper_login'} style={{height:'100vh'}}>


            <div className={'login_back'}>

                <Link to="/">
                    <div className={'back'}>
                        <p> <AiOutlineLeft style={{color:'white',marginRight:"10px"}}/>

                            {t('Back to: Flowle.com')}
                        </p>
                    </div>
                </Link>

            </div>
            <div className={'wrapper_forms'} style={{height:'80vh'}}>
            <div className={'contact_form'}>


                <div >
                    <h2>
                        {t('Thanks Demo Request!')}
                    </h2>
                </div>


                <p>
                    {t('Your request has been sent.')}
                </p>
                <p>

                    {t('We appreciate your')}
                    <br/>
                    {t('in touch with you shortly!')}
                </p>




                </div>

            </div>


        </div>
    );
};

export default ThanksReaching;
