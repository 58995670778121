import React from 'react';
import {motion} from "framer-motion";
import {HashLink as Link} from 'react-router-hash-link';
import {MenuProduct} from "./MenuItens/MenuProduct";
import {useTranslation} from "react-i18next";


const DropdawnProduct =(props) => {

    const animationForm = {opacity: 0, y: -40 }
    const animationTo = {opacity: 1, y: 0 }
    const {t} = useTranslation()
    return (
        <div className={'drowe_link'}>
            <ul>
                {MenuProduct.map((item, index) => {
                    return (
                        <motion.li initial={animationForm}
                                   animate={animationTo}
                                   transition={{delay: 0.20}}
                                   key={index}>
                            <Link

                                className={item.cName}
                                to={item.href}
                                onClick={() => props.isMobile && props.closeMobileMenu()}
                            >
                                {t(item.title)}
                            </Link>

                        </motion.li>
                    )}
                )}
            </ul>
        </div>
    );
};

export default DropdawnProduct;