import React from 'react';
import './footer.scss'
import LogoMap
    from './kisspng-flag-of-switzerland-logo-coat-of-arms-of-switzerla-5be6971bc48270.3943639115418386198049.png'
import Map from './kisspng-world-map-globe-map-5acd5d97b794f6.047312511523408279752.png'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFacebookF, faInstagram, faLinkedinIn} from "@fortawesome/free-brands-svg-icons";
import {useTranslation} from "react-i18next";
import {HashLink as Link} from 'react-router-hash-link';
import {faEnvelope, faMapMarkerAlt, faPhoneAlt} from "@fortawesome/free-solid-svg-icons";

const For = () => {


    const {t} = useTranslation()
    return (
        <div className={'footer_wrapper'}>

            <div className={'triangular'}>

            </div>

            <div className={'footer_description'}>
                <div className={'footer_top'}>

                    <div className={'footer_section_1'}>

                        <h2>
                            {t('Why FLOWLE')}
                        </h2>


                        <ul>
                            <li>

                                <Link
                                    smooth={true}
                                    offset={-70}
                                    duration={500}
                                    to={"whyFlowle/#Areas"}>
                                    {t('Problem Areas')}
                                </Link>

                            </li>
                            <hr/>
                            <li>
                                <Link to="whyFlowle/#Flowle">
                                    {t('The FLOWLE Solution')}

                                </Link>

                            </li>
                            <hr/>
                            <li>
                                <Link to="whyFlowle/#Solution">
                                    {t('What Sets FLOWLE Apart')}

                                </Link>
                            </li>
                        </ul>
                    </div>


                    <div className={'footer_section_2'}>

                        <h2>
                            {t('Product')}
                        </h2>


                        <ul>
                            <li>

                                <Link to={"product/#Smart"}>
                                    Smart Data Analytics
                                </Link>

                            </li>
                            <hr/>
                            <li>
                                <Link to={"product/#Culture"}>
                                    FLOWLE | Culture Data
                                </Link>
                            </li>
                            <hr/>
                            <li>
                                <Link to={"product/#Strategy"}>
                                    FLOWLE | Strategy
                                </Link>

                            </li>
                            <hr/>
                            <li>
                                <Link to={"product/#Recruitment"}>
                                    FLOWLE | Recruitment
                                </Link>

                            </li>
                            <hr/>

                            <li>
                                <Link to={"product/#Leadership"}>
                                    FLOWLE | Leadership
                                </Link>

                            </li>
                            <hr/>
                            <li>
                                <Link to={"product/#Process"}>
                                    {t('Process')}
                                </Link>
                            </li>
                        </ul>

                    </div>


                    <div className={'footer_section_3'}>


                        <h2 id={'contact'}>
                            {t('Contacts')} </h2>
                        <ul>
                            <li><FontAwesomeIcon style={{color: 'white', marginRight: '15px'}} icon={faEnvelope}/>
                                <a href="https://growithy.com/">
                                    www.growithy.com
                                </a>
                            </li>
                            <hr/>
                            <li><FontAwesomeIcon style={{color: 'white', marginRight: '15px'}} icon={faPhoneAlt}/>+41 44
                                431 07 07
                            </li>
                            <hr/>
                            <li><FontAwesomeIcon style={{color: 'white', marginRight: '15px'}}
                                                 icon={faMapMarkerAlt}/>

                            </li>

                            <p className={'li_address'}
                               style={{marginLeft: '25px', marginBottom: '30px', marginTop: '-20px'}}>Growithy AG<br/>
                                Tödistrasse 16<br/>
                                8002 Zürich</p>

                        </ul>

                    </div>


                    <div className={'footer_section_4'}>

                        <h2>
                            {t('About As')}
                        </h2>


                        <ul>
                            <li>

                                <Link to={"aboutAs/#Mission"}>
                                    {t('What We Stand For')}
                                </Link>


                            </li>
                            <hr/>

                            <li>
                                <Link to={"aboutAs/#Story"}>
                                    {t('The FLOWLE Story')}
                                </Link>

                            </li>
                            <hr/>
                            <li>
                                <Link to={"aboutAs/#Meet"}>
                                    {t('Meet the Team')}

                                </Link>
                            </li>
                            <hr/>
                            <li>
                                <Link to={"aboutAs/#Values"}>
                                    {t('Our Values')}

                                </Link>
                            </li>
                            <hr/>
                            <li>
                                <Link to={"aboutAs/#Milestones"}>
                                    {t('Milestones')}

                                </Link>
                            </li>
                        </ul>
                    </div>


                    <div className={'footer_section_5'}>

                        <h2>{t('Benefits')}</h2>

                        <ul>
                            <li>
                                <Link to={"benefits/#Fields"}>
                                    {t('Fields of Application')}
                                </Link>

                            </li>
                            <hr/>
                            <li>

                                <Link to={"benefits/#Profit"}>
                                    {t('How You Profit')}

                                </Link>
                            </li>
                        </ul>
                    </div>


                    <div className={'footer_section_6'}>

                        <h2>SOCIAL</h2>
                        <div className={'media'}>
                            <a hrefLang="chart" target="_blank" style={{position:'relative'}} href="https://instagram.com/growithy?utm_medium=copy_link">
                                <FontAwesomeIcon className={'singnup'} icon={faInstagram}/>
                            </a>


                            <a hrefLang="chart" target="_blank" style={{position:'relative'}} className={'lin'}  href="https://www.facebook.com/FlowleCultureData ">
                                <FontAwesomeIcon className={'singnup'} icon={faLinkedinIn}/>
                            </a>


                            <a hrefLang="chart" target="_blank" style={{position:'relative'}} href="https://www.linkedin.com/company/flowle">

                                <FontAwesomeIcon className={'singnup'} icon={faFacebookF}/>
                            </a>
                        </div>


                    </div>


                </div>

                <div className={'footer_map'}>
                    <div className={'map_logo'}>
                        <img src={LogoMap} alt={'logo'}/>
                    </div>
                    <div>
                        <img src={Map} alt={'map'}/>
                    </div>
                    <hr/>
                </div>
            </div>


            <div className={'footer_address'}>

                <div className={'footer_private'}>
                    <div>

                        <p>© 2022 by GROWITHY AG

                            <Link to={'/policies'}>


                                {' '}<u>{t('Privacy')}{' '}& {t('Cookie Policys')}</u>


                            </Link>
                        </p>
                    </div>


                </div>
            </div>
        </div>
    );
};

export default For;